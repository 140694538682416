import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../modals/Modal';
import { AppContext } from '../../../context/Context';
import Button from '../../buttons/Button';
import Input from '../../inputs/Input';
import SpinnerSmall from '../../spinners/SpinnerSmall';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { Divider } from '@mui/material';

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // justify-content: center;
    // margin-top: ${(props) => props.theme.grid.divider_4};
`;
const TextCentered = styled.div`
    text-align: center;
`;
const FormContent = styled.div`
    padding: 16px;
`;

const IdleModal = () => {
    const history = useHistory();

    const { idle, setIdle, Logout } = useContext(AppContext);
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    return (
        <Modal
            open={idle}
            openModal={() => console.log('This modal is uncloseable')}
            title="Automaattinen uloskirjautuminen"
            closeable={false}>
            <p>
                Huomasimme ettet ole käyttänyt sovellusta 60 minuuttiin. Turvallisuutesi vuoksi
                kirjasimme sinut ulos sovelluksesta. Sinun tulee kirjautua uudestaan käyttääksesi
                sovellusta.
            </p>
            <br />
            <ButtonRow>
                <Button
                    small
                    primary
                    dividerRight
                    onClick={() => {
                        logout();
                        loginWithRedirect({
                            authorizationParams: {
                                redirect_uri: window.location.origin,
                                audience: 'https://mydigimap-back.azurewebsites.net/',
                                scope: 'offline_access'
                            }
                        });
                        sessionStorage.removeItem('logout_message');
                    }}>
                    Kirjaudu uudestaan
                </Button>
                <Button
                    white
                    small
                    onClick={() => {
                        Logout(), setIdle(false), sessionStorage.removeItem('logout_message');
                    }}>
                    Jatka kirjautumatta
                </Button>
            </ButtonRow>
        </Modal>
    );
};

export default IdleModal;
