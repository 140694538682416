import React, { useContext, lazy, Suspense, useEffect, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import theme from './theme';
import styled, { ThemeProvider } from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Provider from './context/Provider';
import { device } from './device';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { AppContext } from './context/Context';

import SideBar from './components/navigations/SideBar';
import Notification from './components/modals/Notification';
import MobileNavigation from './components/navigations/MobileNavigation';
import Spinner from './components/spinners/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
import TopBar from './components/navigations/TopBar';
import FirstLoginModal from './components/patterns/modals/FirstLoginModal';
import FirstTeamModal from './components/patterns/modals/FirstTeamModal.js';
import WhitePage from './pages/WhitePage.js';
import IdleModal from './components/patterns/modals/IdleModal.js';
import AppLogout from './components/utilities/AppLogout.js';

const ErrorPage = lazy(() => import('./pages/ErrorPage'));

const Softwares = lazy(() => import('./pages/Softwares'));
const Integrations = lazy(() => import('./pages/Integrations'));
const Software = lazy(() => import('./pages/Software'));
const Profile = lazy(() => import('./pages/Profile'));
const Practice = lazy(() => import('./pages/Practice'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Contracts = lazy(() => import('./pages/Contracts'));
const Contract = lazy(() => import('./pages/Contract'));
const Projects = lazy(() => import('./pages/Projects'));
const Project = lazy(() => import('./pages/Project'));
const Integration = lazy(() => import('./pages/Integration'));
const Contact = lazy(() => import('./pages/Contact'));
const Login = lazy(() => import('./pages/Login'));
const Logs = lazy(() => import('./pages/Logs'));
const Cost = lazy(() => import('./pages/Cost'));
const SettingsPage = lazy(() => import('./pages/SettingsPage'));

const User = lazy(() => import('./pages/User'));
const Supplier = lazy(() => import('./pages/Supplier'));
const ApiManager = lazy(() => import('./pages/ApiManager'));
const Practices = lazy(() => import('./pages/Practices'));
const RoadMap = lazy(() => import('./pages/RoadMap'));
const Home = lazy(() => import('./pages/Home'));
const Import = lazy(() => import('./pages/Import'));

library.add(fas);

const Content = styled.div`
    margin-top: 48px;
    @media ${device.laptop} {
        margin-top: 60px;
    }
`;

const GlobalStyle = createGlobalStyle`
    body, html {
        margin: 0px;
        padding: 0px;
		max-width: 100%;
		min-height: 100vh;
		font-family: 'Ubuntu', sans-serif !important;
		font-weight: ${(props) => props.theme.fontWeight.regular};
		font-display: swap;
		color: ${(props) => props.theme.colors.black};
		hyphens: manual;
		text-rendering: optimizeLegibility;
		background-color: ${(props) => props.theme.colors.gray_20};
		
	}

    
	h1 {
		font-weight: ${(props) => props.theme.fontWeight.regular};
		font-size: 56px;
		line-height: 62px;
		margin: 0px;
		@media ${device.laptop} {
			font-size: 56px;
			line-height: 64px
		}
    }
    h2 {
		font-weight: ${(props) => props.theme.fontWeight.regular};
		font-size: 42px;
		line-height: 52px;
		margin: 0px;
		@media ${device.laptop} {
			font-size: 	38px;
			line-height: 42px
		}
    }
    h3 {
		font-weight: ${(props) => props.theme.fontWeight.regular};
		font-size: 30px;
		line-height: 38px;
		margin: 0px;
		@media ${device.laptop} {
			font-size: 	28px;
			line-height: 32px
		}
    }
    h4 {
		font-weight: ${(props) => props.theme.fontWeight.regular};
		font-size: 24px;
		line-height: 30px;
		margin: 0px;
		@media ${device.laptop} {
			font-size: 	22px;
			line-height: 28px
		}
    }
    h5 {
		font-weight: ${(props) => props.theme.fontWeight.regular};
		font-size: 20px;
		line-height: 28px;
		margin: 0px;
		@media ${device.laptop} {
			font-size: 	18px;
			line-height: 22px
		}
    }
    h6 {
		font-weight: ${(props) => props.theme.fontWeight.regular};
		font-size: 16px;
		line-height: 18px;
		margin: 0px;
		@media ${device.laptop} {
			font-size: 	14px;
			line-height: 18px
		}
	}
	p {
		font-weight: ${(props) => props.theme.fontWeight.regular};
		font-size: 16px;
		line-height: 24px;
		margin: 0px;
		@media ${device.laptop} {
			font-size: 	14px;
			line-height: 24px
		}

	}
    img {
        max-width: 100%;
	}
	a, a:link, a:visited, a:focus, a:hover, a:active{
		color: inherit;
		text-decoration:none; 
		cursor: pointer;
	  }
  
    button {
		all: unset;
		font-family: 'Ubuntu', sans-serif !important;
		

	}
	button:hover {
		cursor: pointer !important;
	}

    // input {
	// 	all: unset;
	font-family: 'Ubuntu', sans-serif !important;

    //     ::-webkit-input-placeholder {
	// }
	input, select {
		-webkit-box-sizing: border-box;
		   -moz-box-sizing: border-box;
				box-sizing: border-box;
	}
    :-moz-placeholder {
        /* FF 4-18 */
        opacity: 1;
    }
    ::-moz-placeholder {
        /* FF 19+ */
        opacity: 1;
    }
    :-ms-input-placeholder {
        /* IE 10+ */
    }
    ::-ms-input-placeholder {
        /* Microsoft Edge */
    }
    ::placeholder {
        /* modern browser */
    }
    
    
`;

const App = () => {
    const PrivateRoute = ({ component: Component, ...rest }) => {
        const { isAuthenticated } = useAuth0();
        const {
            userProfile,
            loadingUserProfile,
            PushToRoute,
            loadingAuthentication,
            SetLocationPath
        } = useContext(AppContext);
        SetLocationPath();

        if (loadingAuthentication || loadingUserProfile) {
            return <Spinner />;
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        isAuthenticated && userProfile.length > 0 ? (
                            <Component {...props} />
                        ) : (
                            <Home />
                        )
                    }
                />
            );
        }
    };

    const AdminRoute = ({ component: Component, ...rest }) => {
        const { isAuthenticated } = useAuth0();

        const { userProfile, loadingUserProfile, PushToRoute, SetLocationPath } =
            useContext(AppContext);
        SetLocationPath();

        if (loadingUserProfile) {
            return <Spinner />;
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        isAuthenticated &&
                        userProfile.length > 0 &&
                        userProfile[0].roles.length > 0 &&
                        userProfile[0].roles[0].id === 1 ? (
                            <Component {...props} />
                        ) : (
                            PushToRoute()
                        )
                    }
                />
            );
        }
    };
    const APIManagerRoute = ({ component: Component, ...rest }) => {
        const { userProfile, loadingUserProfile, PushToRoute, SetLocationPath } =
            useContext(AppContext);
        SetLocationPath();

        if (loadingUserProfile) {
            return <Spinner />;
        } else {
            return (
                <Route
                    {...rest}
                    render={(props) =>
                        userProfile.permissions != null &&
                        userProfile.length > 0 &&
                        userProfile[0].permissions.length > 0 &&
                        userProfile[0].permissions[0].permission === 'api_manager' &&
                        userProfile[0].permissions[0].access === true ? (
                            <Component {...props} />
                        ) : (
                            PushToRoute()
                        )
                    }
                />
            );
        }
    };
    const GlobalLoader = () => {
        return <Spinner />;
    };

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Provider>
                    <AppLogout />
                    <SideBar />
                    <MobileNavigation />
                    <TopBar title="MyDigiMap" />
                    <FirstLoginModal />
                    <FirstTeamModal />
                    <Notification />
                    <IdleModal />
                    <Suspense fallback={GlobalLoader()}>
                        <Content>
                            <Switch>
                                <Route exact path="/" component={() => <Home />} />
                                <Route exact path="/welcome" component={WhitePage} />
                                <Route exact path="/loginpage" component={Login} />
                                <PrivateRoute exact path="/dashboardpage" component={Dashboard} />
                                <Route exact path="/error" component={ErrorPage} />
                                <PrivateRoute exact path="/roadmap" component={RoadMap} />
                                <PrivateRoute exact path="/integrations" component={Integrations} />
                                <PrivateRoute
                                    exact
                                    path="/integrations/:id"
                                    component={Integration}
                                />
                                <PrivateRoute exact path="/userProfilepage" component={Profile} />
                                <PrivateRoute exact path="/practices" component={Practices} />
                                <PrivateRoute path="/practices/:id" component={Practice} />
                                <PrivateRoute exact path="/softwares" component={Softwares} />
                                <PrivateRoute exact path="/softwares/:id" component={Software} />
                                <PrivateRoute exact path="/contracts" component={Contracts} />
                                <PrivateRoute exact path="/contracts/:id" component={Contract} />
                                <PrivateRoute exact path="/projects" component={Projects} />
                                <PrivateRoute exact path="/projects/:id" component={Project} />
                                <PrivateRoute exact path="/contact" component={Contact} />
                                <PrivateRoute exact path="/logs" component={Logs} />
                                <PrivateRoute exact path="/import" component={Import} />
                                <Route exact path="/costs/:id" component={Cost} />
                                <AdminRoute exact path="/settings" component={SettingsPage} />
                                <APIManagerRoute exact path="/apimanager" component={ApiManager} />
                                <PrivateRoute exact path="/users/:id" component={User} />
                                <PrivateRoute exact path="/suppliers/:id" component={Supplier} />
                            </Switch>
                        </Content>
                    </Suspense>

                    <GlobalStyle />
                </Provider>
            </Router>
        </ThemeProvider>
    );
};

export default App;

if (document.getElementById('app')) {
    createRoot(<App />, document.getElementById('app'));
}
