import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { device } from '../../device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMap,
    faChessKnight,
    faGlobe,
    faTh,
    faFileContract,
    faTasks,
    faComments,
    faDesktop,
    faChevronRight,
    faChevronLeft,
    faWindowMaximize,
    faCog
} from '@fortawesome/free-solid-svg-icons';
import { AppContext } from '../../context/Context';
import { useAuth0 } from '@auth0/auth0-react';

const Wrapper = styled.div`
    position: fixed;
    background-color: ${(props) => props.theme.colors.white};
    border-right: 1px solid ${(props) => props.theme.colors.gray_40};
    width: 64px;
    height: 100vh;
    min-height: 500px;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    z-index: 10000000000;
    ${({ show }) =>
        !show &&
        `
	display: none;
`}
    ${({ open }) =>
        open &&
        `
		width: 180px;
		-webkit-transition: width 0.2s ease-in-out;
		-moz-transition: width 0.2s ease-in-out;
		-o-transition: width 0.2s ease-in-out;
		transition: width 0.2s ease-in-out;
	`}
    @media ${device.laptop} {
        display: none;
    }
`;

const Tooltip = styled.div`
    visibility: hidden;
    background-color: rgb(10, 34, 255, 0.7);
    background-color: ${(props) => props.theme.colors.white};
    text-align: left;
    // line-height: 44px;
    padding: 12px;
    border-radius: 4px;
    position: absolute;
    margin-top: -4px;
    margin-left: 70px;
    // width: 250px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 2px 0px;
    ::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-width: 8px;
        border-style: solid;
        border-color: transparent ${(props) => props.theme.colors.white} transparent transparent;
        // top: 14px;
        left: -16px;
    }
    ${({ sideBarOpen }) =>
        sideBarOpen &&
        `
		display: none
	`};
`;
const TooltipBody = styled.p`
    font-size: 10px;
`;

const Links = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    align-items: left;
`;

const Copywhrite = styled.p`
    margin-bottom: 0px;
    visibility: hidden;
    font-size: 12px;
    ${({ sideBarOpen }) =>
        sideBarOpen &&
        `
		visibility: visible;
		transition-delay: 0.2s;
	`};
`;

const BarsIcon = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: left;
    // &:hover  {
    // 	color: white;
    // 	cursor: pointer;
    // }
`;
const LogoIcon = styled.img`
    // height: 18px;
    padding-left: 16px;
    padding-right: 16px;
`;
const Logo = styled.img`
	align-self: flex-start;
	padding-left: 16px;
    padding-right: 16px;
	max-width: 80%;
}
`;

const IconContainer = styled.div`
    height: 32px;
    min-width: 32px;
    margin-left: 16px;
    // border: solid 1px ${(props) => props.theme.colors.gray_60};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ActiveIconContainer = styled.div`
    height: 32px;
    min-width: 32px;
    margin-left: 16px;
    border: solid 1px ${(props) => props.theme.colors.primary_100};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Icon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.gray_80};
    // color: #6A7196;

    height: 14px;
`;
const Closer = styled.div`
    position: absolute;
    top: 50vh;
    height: 80px;
    background-color: white;
    width: 16px;
    border-radius: 0px 8px 8px 0px;
    border: 1px solid ${(props) => props.theme.colors.gray_40};
    left: 64px;
    ${({ open }) =>
        open &&
        `
		left: 180px;
		-webkit-transition: left 0.2s ease-in-out;
		-moz-transition: left 0.2s ease-in-out;
		-o-transition: left 0.2s ease-in-out;
		transition: left 0.2s ease-in-out;
	`};
    &:hover {
        cursor: pointer;
    }
`;
const CloserContent = styled.div`
    width: 100%;
    text-align: center;
    height: 80px;
    line-height: 80px;
`;

const CloserIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.gray_80};
    // height: 18px;
    font-size: 12px;
`;
const ActiveIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.primary_100};
    height: 18px;
`;
const LinkText = styled.h6`
    color: ${(props) => props.theme.colors.fontDark};
    // color: #6A7196;
    font-size: 14px;
    margin-left: 8px;
    white-space: nowrap;
    line-height: 32px;
    font-weight: 500;
    visibility: hidden;
    ${({ sideBarOpen }) =>
        sideBarOpen &&
        `
		visibility: visible;
		transition-delay: 0.1s;
	`};
`;

const LinkContainer = styled(Link)`
    display: flex;
    flex-direction: row;
    // margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.fontDark};
        background-color: ${(props) => props.theme.colors.gray_20};
        ${Tooltip} {
            visibility: visible;
        }
        ${Icon} {
            color: ${(props) => props.theme.colors.fontDark};
        }
        ${LinkText} {
            color: ${(props) => props.theme.colors.fontDark};
        }
    }
`;
const LinkContainerNoLink = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    &:hover {
        cursor: pointer;
        color: ${(props) => props.theme.colors.fontDark};
        background-color: ${(props) => props.theme.colors.gray_20};
        ${Tooltip} {
            visibility: visible;
        }
        ${Icon} {
            color: ${(props) => props.theme.colors.fontDark};
        }
        ${LinkText} {
            color: ${(props) => props.theme.colors.fontDark};
        }
    }
`;
const routeList = [
    // {
    // 	link: '/',
    // 	icon: faHome,
    // 	title: 'Etusivu',
    // 	ingress: 'Ota palvelu käyttöön tai tarkastele artikkeleita!',
    // 	description: 'Etusivulta pääset helposti käyttämään MyCIO:ta tai lukemaan myös palvelua tukevia artikkeleita, joista pitäisi avautua että miksi tietohallinnon johtaminen on tärkeää'
    // },
    {
        link: '/dashboardpage',
        icon: faTh,
        title: 'Kokonaiskuva',
        ingress: 'Tutki tietohallinnon tunnuslukuja',
        description:
            'Kokonaiskuvasta pääset tarkastelemaan palvelun osa-alueiden tunnusmerkkejä, jotka tukevat tietohallinnon johtamistasi!'
    },
    {
        link: '/roadmap',
        icon: faMap,
        title: 'Tiekartta',
        ingress: 'Kehitä tietohallintoasi strategisesti',
        description:
            'Tiekartta on erinomainen työkalu tietohallinnon kehitysprojektien seuraamiseksi ja johtamiseksi strategiset tavoitteet mielessä pitäen'
    },
    {
        link: '/projects',
        icon: faTasks,
        title: 'Projektit',
        ingress: 'Tietohallinnon kehittämisen seuraamista',
        description:
            'Projekteilla kehitetään tietohallinnon näkökannasta organisaatiota. Näitä voi liittyä toimintoihin, sovelluksiin, integraatioihin tai miksi ei sopimuksiin myös'
    },
    {
        link: '/practices',
        icon: faWindowMaximize,
        title: 'Toiminnot',
        ingress: 'Kartoita toimintosi (toiselta nimeltä prosessisi)',
        description:
            'Toiminnot (eli prosessit) ovat organisaatiosi ydin- ja tukitekemisen elementit. Jollain ne ovat liiketoiminnan alueita, toisella toiminnon aloja mutta kyse on samasta asiasta'
    },
    {
        link: '/softwares',
        icon: faDesktop,
        title: 'Sovellukset',
        ingress: 'Listaa sovelluksesi tietohallinnon hahmottamisen vuoksi',
        description:
            'Sovellukset ovat näkyvintä ja ydintekemistä tietohallinnossa. Näitä käyttävät organisaation eri toiminnot ja ne ovat avainasemassa tietohallinnon johtamista'
    },
    {
        link: '/integrations',
        icon: faGlobe,
        title: 'Integraatiot',
        ingress: ' Kuinka tieto liikkuu sovellusten välillä?',
        description:
            'Integraatiot ovat sovellusten välillä olevia tietoyhteyksiä, joiden avulla sovellukset vaihtavat tietoa keskenään. Tärkeä osa kokonaisarkkitehtuurin tunnistamista'
    },
    {
        link: '/contracts',
        icon: faFileContract,
        title: 'Sopimukset',
        ingress: 'Tietohallinnon hallinnollista johtamista',
        description:
            'Sopimukset ovat tärkeä osa tietohallinnon johtamista. Sopimukset ovat paras tapa harjoittaa riskienhallintaa tietohallinnon näkökannalta'
    }
    // ,
    // {
    //     link: '/settings',
    //     icon: faCog,
    //     title: 'Asetukset',
    //     ingress: '',
    //     description: ''
    // }
];

const SideBar = () => {
    const history = useHistory();
    const {
        sideBarOpen,
        setSideBarOpen,
        userProfile,
        firstLogin,
        loadingAuthentication,
        loadingUserProfile
    } = useContext(AppContext);
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    const NavigationLinks = () => {
        return (
            <Links>
                {routeList.map((item, i) => {
                    return (
                        <LinkContainer key={i} to={item.link}>
                            {history.location.pathname.includes(item.link) ? (
                                <ActiveIconContainer>
                                    <ActiveIcon icon={item.icon} />
                                </ActiveIconContainer>
                            ) : (
                                <IconContainer>
                                    <Icon icon={item.icon} />
                                </IconContainer>
                            )}

                            <Tooltip sideBarOpen={sideBarOpen}>
                                {/* <p style={{ fontWeight: 'bold' }}>
										{item.ingress}
									</p> */}
                                <h6
                                // style={{ color: 'white' }}
                                >
                                    {item.title}
                                </h6>
                                {/* <TooltipBody>
										{item.description}
									</TooltipBody> */}
                            </Tooltip>
                            <LinkText sideBarOpen={sideBarOpen}>{item.title}</LinkText>
                        </LinkContainer>
                    );
                })}
            </Links>
        );
    };
    let path = useLocation();

    useEffect(() => {
        NavigationLinks();
    }, [path]);
    return (
        <Wrapper
            open={sideBarOpen}
            show={
                userProfile.length > 0 &&
                !loadingAuthentication &&
                !loadingUserProfile &&
                isAuthenticated &&
                firstLogin === false
            }>
            {/* <BarsIcon>
                {!sideBarOpen && <LogoIcon src="/images/icon.svg" />}
                {sideBarOpen && <Logo src="/images/logo.svg" />}
            </BarsIcon> */}
            <Closer open={sideBarOpen} onClick={() => setSideBarOpen(!sideBarOpen)}>
                <CloserContent>
                    <CloserIcon icon={!sideBarOpen ? faChevronRight : faChevronLeft} />
                </CloserContent>
            </Closer>
            {NavigationLinks()}
            {/* <LinkContainerNoLink
				style={{
					marginTop: 'auto'
				}}
				to="/contact"
				onClick={() => setSideBarOpen(false)}
			>
		
				
			</LinkContainerNoLink> */}
            {userProfile.length > 0 &&
            userProfile[0].roles.length > 0 &&
            userProfile[0].roles[0].id === 1 ? (
                <LinkContainer to="/settings">
                    <IconContainer>
                        <Icon icon={faCog} />
                    </IconContainer>
                    <LinkText sideBarOpen={sideBarOpen}>Asetukset</LinkText>
                </LinkContainer>
            ) : null}
            {/* 
            <LinkContainer to="/contact">
                <IconContainer>
                    <Icon icon={faComments} />
                </IconContainer>
                <LinkText sideBarOpen={sideBarOpen}>Ota yhteyttä</LinkText>
            </LinkContainer> */}
        </Wrapper>
    );
};

export default SideBar;
