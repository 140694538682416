import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from '../../device';

const Wrapper = styled.div`
    margin-top: ${(props) => props.theme.grid.divider_2};
    width: 100%;
`;

const StyledInput = styled.input`
    background-color: white;
    height: 32px;
    width: calc(100% - 12px);
    border: 1px solid ${(props) => props.theme.colors.gray_80};
    border-radius: 4px;
    font-size: 16px;
    color: ${(props) => props.theme.colors.fontDark};
    padding-left: 12px;
    // padding: 0.4%;
    line-height: 32px;
    ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    ${({ required }) =>
        required &&
        `
		border: 1px solid #0A22FF;
		`}

    box-sizing:content-box;
    ${({ isDisabled }) =>
        isDisabled &&
        `
		border: 1px solid gray;
		background-color: #dddddd;

	`};
`;

const Label = styled.label`
    font-size: 16px;
    font-weight: regular;
    margin-bottom: ${(props) => props.theme.grid.divider_1};
    display: inline-block;
`;

const Input = ({ label, required, placeholder, register, disabled, name, maxLength, type }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const checkDisabled = () => {
        if (disabled != true) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };
    useEffect(() => {
        checkDisabled();
    }, [disabled]);
    return (
        <Wrapper>
            <Label>{required ? label + '*' : label}</Label>
            <StyledInput
                {...register(name, { required, maxLength: maxLength })}
                label={label}
                placeholder={placeholder}
                type={type || 'text'}
                isDisabled={isDisabled}
                disabled={disabled || false}
                required={required}
            />
        </Wrapper>
    );
};

export default Input;
