import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { AppContext } from '../context/Context';
import { useParams, useHistory } from 'react-router-dom';
import { Button, ButtonOutsideLink } from '../components/buttons/Button';
import Select from '../components/inputs/Select';
import { useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import Container from '../components/utilities/Container';
import Spinner from '../components/spinners/Spinner';
import Table from '../components/tables/Table';
import ButtonGoBack from '../components/buttons/ButtonGoBack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import SpinnerSmall from '../components/spinners/SpinnerSmall';
import SelectWithId from '../components/inputs/SelectWithId';
import TitleCard from './TitleCard';
import Input from '../components/inputs/Input';
import { useAuth0 } from '@auth0/auth0-react';
import Section from '../components/utilities/Section';
import version from '../../package.json';

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: ${(props) => props.theme.grid.divider_4};
    margin-left: auto;
`;

const Profile = () => {
    const history = useHistory();
    const [edit, setEdit] = useState(false);

    const {
        teamUser,
        setNotifyMessage,
        loadingUsers,
        loadingUserProfile,
        InsertTeamUser,
        roles,
        DeleteUser,
        users,
        setAppLocation,
        GetTeamUser,
        InsertUser,
        userProfile,
        FirstLoginCheck,
        Logout
    } = useContext(AppContext);
    const { logout, isAuthenticated } = useAuth0();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset
    } = useForm();

    const onSubmit = async (data) => {
        try {
            await InsertUser({
                id: userProfile[0].id || null,
                email: userProfile[0].email || '',
                role: data.role || null,
                first_name: data.first_name || null,
                last_name: data.last_name || null
            });
            setEdit(false);
            reset();
        } catch (err) {
            console.log(err);
            setNotifyMessage(`Jokin meni pieleen ${err}`);
        }
    };

    const TitleCardContainer = () => {
        if (loadingUsers) {
            return <SpinnerSmall />;
        } else {
            return (
                <div>
                    {userProfile.length > 0 ? (
                        <TitleCard
                            title={userProfile[0].first_name + ' ' + userProfile[0].last_name}
                            edit={() => setEdit(!edit)}
                            icon={faUser}>
                            {!edit ? (
                                <Table
                                    items={[
                                        {
                                            header: 'Etunimi',
                                            data: userProfile ? userProfile[0].first_name : '-'
                                        },
                                        {
                                            header: 'Sukunimi',
                                            data: userProfile ? userProfile[0].last_name : '-'
                                        },
                                        {
                                            header: 'Email',
                                            data: userProfile ? userProfile[0].email : '-'
                                        },
                                        {
                                            header: 'Rooli',
                                            data:
                                                userProfile[0].roles &&
                                                userProfile[0].roles.length > 0
                                                    ? userProfile[0].roles[0].title
                                                    : '-'
                                        }
                                    ]}
                                />
                            ) : (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Input
                                        label="Etunimi"
                                        name="first_name"
                                        register={register}
                                        type="text"
                                        required
                                    />
                                    {errors.first_name && <p>Etunimi on pakollinen tieto.</p>}
                                    <Input
                                        label="Sukunimi"
                                        name="last_name"
                                        register={register}
                                        type="text"
                                        required
                                    />
                                    {errors.last_name && <p>Sukunimi on pakollinen tieto.</p>}
                                    <Input
                                        label="Email"
                                        name="email"
                                        register={register}
                                        type="email"
                                        disabled
                                    />
                                    <SelectWithId
                                        name="role"
                                        label="Rooli"
                                        placeholder=""
                                        register={register}
                                        options={roles}
                                        disabled
                                    />

                                    <ButtonRow>
                                        <Button dividerRight primary type="submit">
                                            Tallenna
                                        </Button>
                                        <Button
                                            dividerRight
                                            white
                                            type="reset"
                                            onClick={() => {
                                                setEdit(false);
                                            }}>
                                            Peruuta
                                        </Button>
                                    </ButtonRow>
                                </form>
                            )}
                        </TitleCard>
                    ) : null}
                </div>
            );
        }
    };
    const Delete = () => {
        window.confirm('Oletko varma että haluat passivoida valitun käyttäjän tilin?') &&
            DeleteUser(userProfile[0].id);
        history.push('/organization');
    };

    useEffect(() => {
        window.scroll(0, 0);
        setAppLocation('Käyttäjä');
    }, []);

    useEffect(() => {
        TitleCardContainer();
    }, [userProfile]);

    useEffect(() => {
        reset({
            first_name: userProfile.length > 0 ? userProfile[0].first_name : '',
            last_name: userProfile.length > 0 ? userProfile[0].last_name : '',
            email: userProfile.length > 0 ? userProfile[0].email : '',
            role:
                userProfile.length > 0 && userProfile[0].roles && userProfile[0].roles.length > 0
                    ? userProfile[0].roles[0].id
                    : '-'
        });
    }, [userProfile]);
    return (
        <Container>
            {loadingUserProfile ? <Spinner /> : TitleCardContainer()}
            {isAuthenticated ? (
                <div>
                    <Button onClick={() => Logout()} primary type="button">
                        Kirjaudu ulos
                    </Button>
                </div>
            ) : (
                <ButtonOutsideLink href="/login" primary type="button">
                    Kirjaudu palveluun
                </ButtonOutsideLink>
            )}
            <Section>
                <p>© 2023 Tahto Group Oy</p>
                <p>support@mydigimap.com</p>
                <p>Ohjelmistoversio: 14-12-2023 {version ? version.version : null}</p>
            </Section>
        </Container>
    );
};

export default Profile;
