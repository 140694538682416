import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { device } from '../../device';

const Wrapper = styled.div`
    width: 100%;
    margin-top: ${(props) => props.theme.grid.divider_2};
`;

const StyledSelect = styled.select`
    height: 32px;
    width: calc(100% - 12px);
    border: 1px solid ${(props) => props.theme.colors.gray_80};
    border-radius: 4px;
    font-size: 16px;
    color: ${(props) => props.theme.colors.fontDark};
    padding-left: 12px;
    line-height: 32px;
    -ms-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    ${({ isDisabled }) =>
        isDisabled &&
        `
		border: 1px solid gray;
		background-color: #dddddd;

	`};
    @media ${device.laptop} {
    }
`;

const Label = styled.label`
    font-size: 16px;
    font-weight: regular;
    margin-bottom: ${(props) => props.theme.grid.divider_1};
    display: inline-block;
    ${({ isDisabled }) =>
        isDisabled &&
        `
		color: gray;

	`};
`;

const SelectWithId = ({
    label,
    required,
    register,
    name,
    options,
    disabled,
    onChange,
    userSelector,
    userSelectorWithId
}) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const checkOptions = () => {
        if (options && options.length > 0 && disabled != true) {
            setIsDisabled(false);
        } else {
            setIsDisabled(true);
        }
    };
    useEffect(() => {
        checkOptions();
    }, [options]);
    const Selector = (item) => {
        if (userSelector === true) return item.user;
        else if (userSelectorWithId === true) return item.id;
    };
    return (
        <Wrapper onChange={onChange}>
            <Label isDisabled={isDisabled}>{required ? label + '*' : label}</Label>
            <StyledSelect
                {...register(name, { required })}
                label={label}
                isDisabled={isDisabled}
                disabled={disabled || false}>
                <option hidden></option>
                <option value={null}></option>

                {options &&
                    options.length > 0 &&
                    options.map((item, i) => {
                        return (
                            <option
                                key={i}
                                value={
                                    userSelector || userSelectorWithId ? Selector(item) : item.id
                                }>
                                {userSelector || userSelectorWithId
                                    ? `${item.first_name} ${item.last_name}`
                                    : item.title}
                            </option>
                        );
                    })}
            </StyledSelect>
        </Wrapper>
    );
};

export default SelectWithId;
