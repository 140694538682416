import React from 'react';
import styled from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';
import { device } from '../../device';

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    height: 100vh;
    @media ${device.laptop} {
        width: 100%;
        height: 50vh;
    }
`;
const Title = styled.p`
    color: ${(props) => props.theme.colors.gray_50};
    margin-bottom: 20px;
`;

const Spinner = ({ title }) => {
    return (
        <SpinnerContainer>
            <Title>{title || ''}</Title>
            <PulseLoader color="#BDBDBD" />
        </SpinnerContainer>
    );
};

export default Spinner;
