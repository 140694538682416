import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../context/Context';
import { ToastContainer, toast } from 'react-toastify';
import '../../css/ReactToastify.css';

const Notification = () => {
    const { notifyMessage, setNotifyMessage } = useContext(AppContext);
    const FireMessage = () => {
        if (notifyMessage != '') {
            toast.info(`${notifyMessage}`, {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
            setNotifyMessage('');
        }
    };
    useEffect(() => {
        FireMessage();
    }, [notifyMessage]);
    return <ToastContainer />;
};

export default Notification;
