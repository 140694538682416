import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../modals/Modal';
import { AppContext } from '../../../context/Context';
import Button from '../../buttons/Button';
import Input from '../../inputs/Input';
import { useForm } from 'react-hook-form';

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: ${(props) => props.theme.grid.divider_4};
`;
const TextCentered = styled.div`
    text-align: center;
`;
const FormContent = styled.div`
    padding: 16px;
`;

const FirstTeamModal = () => {
    const { noTeam } = useContext(AppContext);
    const [modalOpen, setModalOpen] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();
    const onSubmit = (data) => {
        reset();
    };
    return (
        <Modal open={noTeam} openModal={() => setModalOpen(!modalOpen)} title="" closeable={false}>
            <FormContent>
                <TextCentered>
                    <h2>Lisää tiimi!</h2>
                    <br></br>
                    <h5>
                        Huomasimme ettet kuulu tiimiin. Sinun tulee luoda oma tiimi jotta voit
                        käyttää MyDigiMappia.
                    </h5>
                    <br></br>
                    <p>Voit kutsua muita käyttäjiä tiimisi sähköpostilla tiimin asetuksista.</p>
                </TextCentered>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Input
                        label="Tiimin nimi"
                        name="title"
                        register={register}
                        disabled={false}
                        required
                    />
                    {errors.title && <p>Tiimin nimi on pakollinen tieto.</p>}

                    <ButtonRow>
                        <Button primary dividerRight type="submit">
                            Tallenna
                        </Button>
                        <Button type="reset" white>
                            Peruuta
                        </Button>
                    </ButtonRow>
                </form>
            </FormContent>
        </Modal>
    );
};

export default FirstTeamModal;
