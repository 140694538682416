import React from 'react';
import styled from 'styled-components';
import { device } from '../../device';

const Wrapper = styled.section`
    margin-top: ${(props) => props.theme.grid.divider_4};
    margin-bottom: ${(props) => props.theme.grid.divider_4};
    @media ${device.laptop} {
        padding-bottom: ${(props) => props.theme.grid.divider_4};
        border-bottom: 1px solid ${(props) => props.theme.colors.gray_60};
    }
`;

const Section = ({ children }) => {
    return <Wrapper>{children}</Wrapper>;
};

export default Section;
