import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { AppContext } from '../../context/Context';
import { device } from '../../device';
import Button from '../buttons/Button';
import SpinnerSmall from '../spinners/SpinnerSmall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faChevronDown, faClipboardList, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '@auth0/auth0-react';
import { keyframes } from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';
import FormAddTeam from '../forms/FormAddTeam';

const Wrapper = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    position: fixed;
    height: 48px;
    margin-top: -48px;
    width: 100%;
    z-index: 10000000000;
    display: flex;
    align-items: center;
    flex-direction: row;
    // padding-left: ${(props) => props.theme.grid.divider_10};
    border-bottom: 1px solid ${(props) => props.theme.colors.gray_40};
    align-items: center;

    @media ${device.laptop} {
        display: none;
    }
`;
const Header = styled.h6`
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 16px;
    line-height: ${(props) => props.theme.grid.divider_8};
    white-space: nowrap;
    // margin-left: ${(props) => props.theme.grid.divider_10};
    // margin-left: 16px;
    @media ${device.laptop} {
    }
`;

const Links = styled.div`
    display: flex;
    flex-wrap: no-wrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-right: ${(props) => props.theme.grid.divider_4};
`;
const Icon = styled(FontAwesomeIcon)`
    // color: ${(props) => props.theme.colors.gray_100};
    color: #6a7196;
    height: 24px;
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
`;
const ActiveIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.primary_100};
    height: 18px;
    min-width: 64px;
`;
const IconContainer = styled(Link)`
    height: 48px;
    width: 32px;
    margin-left: 11px;
    // border: solid 1px ${(props) => props.theme.colors.gray_60};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ActiveIconContainer = styled(Link)`
    height: 32px;
    width: 32px;
    margin-left: 11px;
    border: solid 1px ${(props) => props.theme.colors.primary_100};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const LogoIcon = styled.img`
    height: 30px;
`;
const LogoIconContainer = styled.div`
    height: 48px;
    width: 84px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NameContainer = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    // margin-right: 16px;
    margin-left: 6px;
`;

const Name = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
    margin-left: 11px;
`;

const TeamSelectorContainer = styled.div`
    display: flex;
    height: 48px;
    align-items: center;

    margin-left: 16px;
`;

const ActiveTeamNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 32px;
    margin-right: 12px;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    &:hover {
        -webkit-transition: background-color 0.5s;
        -moz-transition: background-color 0.5s;
        -o-transition: background-color 0.5s;
        transition: background-color 0.5s;
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.primary_5};
    }
`;

const ActiveTeamName = styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
`;

const DropDownIcon = styled.div`
    height: 32px;
    line-height: 32px;
    font-size: 12px;
    margin-left: 16px;
`;
const breatheAnimation = keyframes`
	0% { height: 0px; width: 0px;  }
	30% { height: 20%; width: 20%;  }
	40% { height: 60%; width: 60% ; }
	100% { height: 100%; width: 100%;  }
`;
const Dropdown = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray_60};
    border-radius: 8px;
    position: absolute;
    top: 40px;
    // margin-left: -16px;
    min-width: 250px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    // animation-name: ${breatheAnimation};
    // animation-duration: 1s;
    // animation-iteration-count: 1;
`;

const DropdownContent = styled.div`
    padding: 8px;
`;

const ActiveTeam = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    height: 32px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border: solid ${(props) => props.theme.colors.primary_100} 0.5px;
    background-color: ${(props) => props.theme.colors.primary_5};
    margin-top: 10px;
`;
const AddTeam = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    height: 32px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    // border: solid ${(props) => props.theme.colors.primary_100} 0.5px;
    // background-color: ${(props) => props.theme.colors.primary_5};
    margin-top: 10px;
    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.primary_5};
    }
`;
const Title = styled.p`
	color: ${(props) => props.theme.colors.primary_100};
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-right: 20px;
	color: €{props => props.theme.colors.gray_100};
`;

const CheckIcon = styled.div`
    color: ${(props) => props.theme.colors.primary_100};
    font-size: 12px;
    line-height: 18px;
    margin-left: auto;
`;

const InitialsCircle = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background-color: green;
`;
const Initials = styled.div`
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
`;

const TeamListName = styled.p`
    // color: ${(props) => props.theme.colors.primary_100};
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin-right: 20px;
`;
const ListTeam = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    height: 32px;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.theme.colors.primary_5};
    }
`;

const TopBar = () => {
    const history = useHistory();
    const {
        userProfile,
        teams,
        loadingTeams,
        activeTeam,
        loadingUsers,
        InsertActiveTeam,
        Logout,
        loadingAuthentication,
        dropdownRef,
        openDropdown,
        setOpenDropdown
    } = useContext(AppContext);
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
    const [openTeamSelector, setOpenTeamSelector] = useState(false);
    const [addTeam, setAddTeam] = useState(false);
    const NameInitialsSelector = () => {
        if (
            userProfile.length > 0 &&
            userProfile[0].first_name.length > 0 &&
            userProfile[0].first_name !== null
        ) {
            return userProfile[0].first_name.charAt(0);
        } else if (
            userProfile.length > 0 &&
            userProfile[0].email !== '' &&
            userProfile[0].email !== null
        ) {
            return userProfile[0].email.charAt(0);
        } else {
            return;
        }
    };
    const TeamsList = () => {
        return teams && teams.length > 0 ? (
            loadingAuthentication || loadingUsers ? (
                <SpinnerSmall />
            ) : (
                teams
                    .filter((item) => item.id != activeTeam[0].id)
                    .map((item, i) => {
                        return (
                            <ListTeam key={i} onClick={() => InsertActiveTeam({ id: item.id })}>
                                <TeamListName>{item.title}</TeamListName>
                            </ListTeam>
                        );
                    })
            )
        ) : null;
    };

    const TeamsSelector = () => {
        return (teams && teams.length > 1) ||
            (userProfile.length > 0 &&
                userProfile[0].permissions.length > 0 &&
                userProfile[0].permissions[0].permission === 'api_manager' &&
                userProfile[0].permissions[0].access === true &&
                activeTeam.length > 0 &&
                activeTeam.title !== null) ? (
            <TeamSelectorContainer>
                <ActiveTeamNameContainer
                    onClick={() => {
                        setOpenTeamSelector(!openTeamSelector), setOpenDropdown(true);
                    }}>
                    <ActiveTeamName>
                        {activeTeam.length > 0 ? activeTeam[0].title : ''}
                    </ActiveTeamName>
                    <DropDownIcon>
                        <FontAwesomeIcon icon={faChevronDown} />
                    </DropDownIcon>
                </ActiveTeamNameContainer>

                {openTeamSelector ? (
                    <Dropdown ref={dropdownRef}>
                        <DropdownContent>
                            <ActiveTeam>
                                <Title>{activeTeam.length > 0 ? activeTeam[0].title : ''}</Title>
                                <CheckIcon>
                                    <FontAwesomeIcon icon={faCheck} />
                                </CheckIcon>
                            </ActiveTeam>

                            {TeamsList()}
                            {userProfile.length > 0 &&
                            userProfile[0].permissions.length > 0 &&
                            userProfile[0].permissions[0].permission === 'api_manager' &&
                            userProfile[0].permissions[0].access === true ? (
                                <AddTeam onClick={() => setAddTeam(!addTeam)}>
                                    <Title>Luo uusi tiimi</Title>
                                    <CheckIcon>
                                        <FontAwesomeIcon icon={faPlus} />
                                    </CheckIcon>
                                </AddTeam>
                            ) : null}
                        </DropdownContent>
                    </Dropdown>
                ) : null}
            </TeamSelectorContainer>
        ) : (
            <TeamSelectorContainer>
                <ActiveTeamName>{activeTeam.length > 0 ? activeTeam[0].title : ''}</ActiveTeamName>
            </TeamSelectorContainer>
        );
    };

    const TopNavigation = () => {
        return (
            <Header>
                <LogoIconContainer>
                    <LogoIcon src="/images/icon.svg" />
                </LogoIconContainer>
                {loadingUsers ? (
                    <LogoIconContainer>
                        <ClipLoader color="#BDBDBD" size={20} />
                    </LogoIconContainer>
                ) : isAuthenticated && userProfile.length > 0 && userProfile[0].teams.length > 0 ? (
                    TeamsSelector()
                ) : null}

                {isAuthenticated && userProfile.length > 0 && userProfile[0].email.length > 0 ? (
                    <Links>
                        <NameContainer to={`/userProfilepage`}>
                            <InitialsCircle>
                                <Initials>{NameInitialsSelector()}</Initials>
                            </InitialsCircle>

                            <Name>{userProfile[0] ? userProfile[0].email : ''}</Name>
                        </NameContainer>
                    </Links>
                ) : userProfile.length > 0 ? null : (
                    <Links>
                        <Button
                            primary
                            xsmall
                            type="button"
                            onClick={() => {
                                Logout();
                                loginWithRedirect({
                                    authorizationParams: {
                                        redirect_uri: window.location.origin,
                                        audience: 'https://mydigimap-back.azurewebsites.net/',
                                        scope: 'offline_access'
                                    }
                                });
                            }}>
                            Kirjaudu
                        </Button>
                    </Links>
                )}
            </Header>
        );
    };

    useEffect(() => {
        TeamsSelector();
    }, [teams, activeTeam, userProfile]);
    useEffect(() => {
        TopNavigation();
    }, [userProfile]);
    useEffect(() => {
        if (openDropdown === false) {
            setOpenTeamSelector(false);
        }
    }, [openDropdown]);

    return (
        <Wrapper>
            {TopNavigation()}{' '}
            <FormAddTeam title="Luo uusi tiimi" open={addTeam} toggleOpen={setAddTeam} />
        </Wrapper>
    );
};

export default TopBar;
