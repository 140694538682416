import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { device } from '../../device';
import Button, { ButtonInsideLink } from '../buttons/Button';
import Container from './Container';

const Wrapper = styled.div`
    ${({ visible }) =>
        !visible &&
        `
		display: none;
	`};

    background-color: ${(props) => props.theme.colors.notification_blue_50};
    border-bottom: 1px solid ${(props) => props.theme.colors.notification_blue_100};
    padding-top: ${(props) => props.theme.grid.divider_2};
    padding-bottom: ${(props) => props.theme.grid.divider_2};
    ${({ card }) =>
        card &&
        `
		border: 1px solid #DEECFF;
		border-radius: 8px;
		margin-top: 24px;
		margin-bottom: 24px;

	`};
    @media ${device.laptop} {
        ${({ hideOnMobile }) =>
            hideOnMobile &&
            `
			display: none;
	`};
    }
`;
const BannerContainer = styled(Container)`
    // margin-left: auto;
    // margin-right: auto;
    display: flex;
    flex-direction: row;
    padding-bottom: ${(props) => props.theme.grid.divider_2};
    @media ${device.laptop} {
        padding-left: ${(props) => props.theme.grid.divider_2};
        padding-right: ${(props) => props.theme.grid.divider_4};
    }
    ${({ card }) =>
        card &&
        `
		padding-left: 16px;
	`};
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    // padding-left: ${(props) => props.theme.grid.divider_4};
    // padding: 24px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    color: ${(props) => props.theme.colors.fontDarkLight};
    // background-color: ${(props) => props.theme.colors.white};
    @media ${device.laptop} {
        // padding-left: ${(props) => props.theme.grid.divider_1};
    }
`;

const Title = styled.h6`
    font-weight: bold;
    margin-bottom: ${(props) => props.theme.grid.divider_1};
`;

const Ingress = styled.p``;
const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: ${(props) => props.theme.grid.divider_4};
`;

const Icon = styled(FontAwesomeIcon)`
    display: none;
    align-self: center;
    font-size: 22px;
    justify-content: flex-start;
    color: ${(props) => props.theme.colors.fontDarkLight};
    margin-left: auto;
    margin-right: 16px;
    &:hover {
        cursor: pointer;
    }
    @media ${device.laptop} {
        margin-right: 0px;
        align-self: flex-start;
    }
    ${({ closeable }) =>
        closeable &&
        `
		display: block;

	`};
`;
const BannerInstructions = ({
    title,
    info,
    card,
    url,
    buttonText,
    closeable,
    hideOnMobile,
    onClick
}) => {
    const [visible, setVisible] = useState(true);
    const SetToLocalStorage = () => {
        localStorage.setItem(`banner="${title}"`, false);
    };

    const GetFromLocalStorage = async () => {
        try {
            if (localStorage.getItem(`banner="${title}"`, 'false')) {
                setVisible(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        GetFromLocalStorage();
    }, []);
    return (
        <Wrapper card={card} visible={visible} hideOnMobile={hideOnMobile}>
            <BannerContainer card={card}>
                {/* <ImageContainer>
				<Image src={image_url || "/images/tahto-logo.png"} alt="Image" />
			</ImageContainer> */}
                <TextContainer>
                    {/* <CategoryTagText>
					{category || "Category"}
				</CategoryTagText> */}
                    <Title>{title || ''}</Title>
                    <Ingress>{info || ''}</Ingress>
                    {url ? (
                        <ButtonRow>
                            <ButtonInsideLink small primary to={url}>
                                {(buttonText && buttonText) || ''}
                            </ButtonInsideLink>
                        </ButtonRow>
                    ) : null}
                    {onClick ? (
                        <ButtonRow>
                            <Button type="button" small primary onClick={() => onClick()}>
                                {(buttonText && buttonText) || ''}
                            </Button>
                        </ButtonRow>
                    ) : null}
                </TextContainer>
                <Icon
                    closeable={closeable}
                    icon={faTimes}
                    onClick={() => {
                        setVisible(!visible);
                        SetToLocalStorage();
                    }}
                />
            </BannerContainer>
        </Wrapper>
    );
};

export default BannerInstructions;
