import React from 'react';
import styled from 'styled-components';
import { device } from '../../device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import BackgroundOverlay from '../utilities/BackgroundOverlay';

const ModalWrapper = styled.div`
    visibility: hidden;
    z-index: 1000000000000000;
    display: flex;
    position: fixed;
    left: 0px;
    right: 0px;
    width: 100%;
    top: 0px;
    bottom: 0px;
    height: 100vh;
    overflow-y: scroll;

    ${({ open }) =>
        open &&
        `
		visibility: visible
	`}
`;

const ModalBox = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 10000000000;
    border: solid 1px blue;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin-top: ${(props) => props.theme.grid.divider_12};
    margin-bottom: auto;
    max-width: 90%;

    width: 500px;
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.primary_200};
    border-radius: 8px;
    padding: ${(props) => props.theme.grid.divider_2};

    @media ${device.laptop} {
        max-width: 80%;
        margin-top: ${(props) => props.theme.grid.divider_10};
    }
`;

const Content = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Icon = styled(FontAwesomeIcon)`
	position: absolute;
	// top: 20px;
	margin-left: auto:
	margin-right: 20px;
	color: ${(props) => props.theme.colors.fontDark};
	font-size: 20px;
	margin-right: ${(props) => props.theme.grid.divider_1};
	align-self: flex-end;
	margin-bottom: ${(props) => props.theme.grid.divider_4};
	&:hover{
		cursor: pointer;
	}

	`;

const ModalTitle = styled.h5`
    margin-bottom: ${(props) => props.theme.grid.divider_2};
    font-weight: 400;
`;

const Modal = ({ children, open, openModal, title, closeable, white }) => {
    return (
        <ModalWrapper open={open}>
            <ModalBox>
                <Content>
                    {closeable === false ? null : (
                        <Icon onClick={() => openModal(false)} icon={faTimes} />
                    )}
                    <ModalTitle>{title || ''}</ModalTitle>
                    {children}
                </Content>
            </ModalBox>
            <BackgroundOverlay onClick={() => openModal(false)} white={white} />
        </ModalWrapper>
    );
};

export default Modal;
