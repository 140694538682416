import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../device';

const StyledButton = styled.button`
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    // letter-spacing: 0.3px;
    padding-left: 22px;
    padding-right: 22px;
    font-size: 14px;
    text-align: center;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Ubuntu;
    ${({ small }) =>
        small &&
        `
		height: 48px;
		line-height: 48px;
		padding-left: 22px;
		padding-right: 22px;
		font-size: 14px;
	`};
    ${({ xsmall }) =>
        xsmall &&
        `
		height: 32px;
		line-height: 32px;
		padding-left: 22px;
		padding-right: 22px;
		font-size: 12px;
	`};
    ${({ disabled }) =>
        disabled &&
        `
		-webkit-text-fill-color: #AEAEAE;
		color: #AEAEAE;
		background-color: #DBDBDB;	
	`};

    ${({ primary }) =>
        primary &&
        `
	-webkit-text-fill-color: #ffffff;
	color: #ffffff;
	background-color: #0A22FF;	
`};

    ${({ white }) =>
        white &&
        `
		-webkit-text-fill-color: #252525;
  		color: #252525 !important;
		background-color: #FBFBFB;
		border: 1px solid #252525;
		font-weight: 400;


	`};
    ${({ success }) =>
        success &&
        `
		-webkit-text-fill-color: #ffffff;
		color: #ffffff;
		background-color: #09A742;

	`};

    ${({ alert }) =>
        alert &&
        `
		-webkit-text-fill-color: #ffffff;
		color: #ffffff;
		background-color: #8E0000;
	`};
    ${({ dividerRight }) =>
        dividerRight &&
        `
		margin-right: 24px;

	`};
    ${({ dividerLeft }) =>
        dividerLeft &&
        `
		margin-left: 24px;
	`};

    @media ${device.laptop} {
        height: 48px;
        line-height: 48px;
        padding-left: 22px;
        padding-right: 22px;
        font-size: 14px;
    }
`;

export const ButtonOutsideLink = ({
    style,
    download,
    href,
    target,
    onClick,
    type,
    form,
    children,
    primary,
    color,
    className,
    textColor,
    small,
    white,
    success,
    alert,
    dividerRight,
    dividerLeft,
    xsmall
}) => {
    return (
        <a href={href || '/'} target={target}>
            <StyledButton
                xsmall={xsmall}
                dividerLeft={dividerLeft}
                download={download}
                style={style}
                className={className}
                color={color}
                textColor={textColor}
                onClick={onClick}
                small={small}
                white={white}
                success={success}
                alert={alert}
                primary={primary}
                dividerRight={dividerRight}
                type={type}
                form={form}>
                {children}
            </StyledButton>
        </a>
    );
};

export const ButtonInsideLink = ({
    style,
    onClick,
    type,
    form,
    children,
    to,
    primary,
    color,
    className,
    textColor,
    small,
    white,
    success,
    alert,
    dividerRight,
    dividerLeft,
    xsmall
}) => {
    return (
        <Link to={to || ''}>
            <StyledButton
                xsmall={xsmall}
                dividerLeft={dividerLeft}
                style={style}
                className={className}
                color={color}
                textColor={textColor}
                onClick={onClick}
                small={small}
                white={white}
                success={success}
                alert={alert}
                primary={primary}
                dividerRight={dividerRight}
                type={type}
                form={form}>
                {children}
            </StyledButton>
        </Link>
    );
};

export const Button = ({
    style,
    download,
    disabled,
    target,
    onClick,
    type,
    form,
    children,
    primary,
    color,
    className,
    textColor,
    small,
    white,
    success,
    alert,
    dividerRight,
    dividerLeft,
    xsmall
}) => {
    return (
        <StyledButton
            xsmall={xsmall}
            dividerLeft={dividerLeft}
            download={download}
            target={target}
            style={style}
            className={className}
            color={color}
            textColor={textColor}
            onClick={onClick}
            small={small}
            white={white}
            success={success}
            alert={alert}
            primary={primary}
            dividerRight={dividerRight}
            type={type || 'button'}
            form={form}
            disabled={disabled}>
            {children}
        </StyledButton>
    );
};

export default Button;
