import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { device } from '../../device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth0 } from '@auth0/auth0-react';

import {
    faMap,
    faChessKnight,
    faWindowMaximize,
    faGlobe,
    faTh,
    faFileContract,
    faTasks,
    faBars,
    faTimes,
    faClipboardList,
    faCog,
    faSignOutAlt,
    faSignInAlt,
    faUser
} from '@fortawesome/free-solid-svg-icons';
import SpinnerSmall from '../spinners/SpinnerSmall';
import { AppContext } from '../../context/Context';

const Wrapper = styled.div`
    display: none;
    overflow-y: auto;
    height: 100%;
    margin-top: 60px;
    z-index: 1000000000000000000;
    position: fixed;
    background-color: ${(props) => props.theme.colors.white};
    visibility: hidden;
    top: 0px;
    left: 0px;
    width: 100%;
    max-width: 100vw;
    ${({ open }) =>
        open &&
        `
		visibility: visible;
		-webkit-transition: right 0.8s ease-in-out;
		-moz-transition: right 0.8s ease-in-out;
		-o-transition: right 0.8s ease-in-out;
		transition: right 0.8s ease-in-out;
	`}

    @media ${device.laptop} {
        display: flex;
    }
`;
const TopBar = styled.div`
    position: fixed;
    background-color: white;
    display: none;
    flex-direction: row;
    top: 0px;
    left: 0px;
    width: 100%;
    max-width: 100vw;
    height: 60px;
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center;
    align-items: center;

    z-index: 100000000;
    @media ${device.laptop} {
        display: flex;
    }
`;

const Links = styled.div`
    padding-left: ${(props) => props.theme.grid.divider_2};
    padding-right: ${(props) => props.theme.grid.divider_2};
    padding-top: ${(props) => props.theme.grid.divider_2};

    height: 1000px;
    width: 100vw;
    ${({ navOpen }) =>
        navOpen &&
        `
		visibility: visible;
		transition-delay: 0.2s;
	`};
`;

const Copywhrite = styled.div`
    margin-bottom: 24px;
    visibility: hidden;
    ${({ navOpen }) =>
        navOpen &&
        `
		visibility: visible;
		transition-delay: 0.2s;
	`};
`;
const BarsIconContainer = styled.div`
    display: flex;
    flex: 1;
    padding-right: 20px;
`;

const BarsIcon = styled.div`
    margin-left: auto;
`;

const LogoContainer = styled.div`
    display: flex;
    flex: 1;
`;

const LogoImage = styled.img`
    max-width: 140px;
`;

const IconContainer = styled.div`
    height: 40px;
    width: 40px;
    border: solid 1px ${(props) => props.theme.colors.gray_60};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const ActiveIconContainer = styled.div`
    height: 40px;
    width: 40px;
    border: solid 1px ${(props) => props.theme.colors.primary_100};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Icon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.gray_100};
    height: 24px;
    min-width: 64px;
`;

const ActiveIcon = styled(FontAwesomeIcon)`
    color: ${(props) => props.theme.colors.primary_100};
    height: 24px;
    min-width: 64px;
`;
const LinkText = styled.h5`
    color: ${(props) => props.theme.colors.gray_100};
    margin-left: 28px;
    white-space: nowrap;
    line-height: 40px;
    font-weight: 500;
    visibility: hidden;
    ${({ navOpen }) =>
        navOpen &&
        `
		visibility: visible;
		transition-delay: 0.1s;
	`};
`;

const LinkContainer = styled(Link)`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`;

const LoginLinkContainer = styled(Link)`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
`;
const routeList = [
    {
        link: '/dashboardpage',
        icon: faTh,
        title: 'Kokonaiskuva',
        ingress: 'Tutki tietohallinnon tunnuslukuja',
        description:
            'Kokonaiskuvasta pääset tarkastelemaan palvelun osa-alueiden tunnusmerkkejä, jotka tukevat tietohallinnon johtamistasi!'
    },
    {
        link: '/roadmap',
        icon: faMap,
        title: 'Tiekartta',
        ingress: 'Kehitä tietohallintoasi strategisesti',
        description:
            'Tiekartta on erinomainen työkalu tietohallinnon kehitysprojektien seuraamiseksi ja johtamiseksi strategiset tavoitteet mielessä pitäen'
    },
    {
        link: '/projects',
        icon: faTasks,
        title: 'Projektit',
        ingress: 'Tietohallinnon kehittämisen seuraamista',
        description:
            'Projekteilla kehitetään tietohallinnon näkökannasta organisaatiota. Näitä voi liittyä toimintoihin, sovelluksiin, integraatioihin tai miksi ei sopimuksiin myös'
    },
    {
        link: '/practices',
        icon: faChessKnight,
        title: 'Toiminnot',
        ingress: 'Kartoita toimintosi (toiselta nimeltä prosessisi)',
        description:
            'Toiminnot (eli prosessit) ovat organisaatiosi ydin- ja tukitekemisen elementit. Jollain ne ovat liiketoiminnan alueita, toisella toiminnon aloja mutta kyse on samasta asiasta'
    },
    {
        link: '/softwares',
        icon: faWindowMaximize,
        title: 'Sovellukset',
        ingress: 'Listaa sovelluksesi tietohallinnon hahmottamisen vuoksi',
        description:
            'Sovellukset ovat näkyvintä ja ydintekemistä tietohallinnossa. Näitä käyttävät organisaation eri toiminnot ja ne ovat avainasemassa tietohallinnon johtamista'
    },
    {
        link: '/integrations',
        icon: faGlobe,
        title: 'Integraatiot',
        ingress: ' Kuinka tieto liikkuu sovellusten välillä?',
        description:
            'Integraatiot ovat sovellusten välillä olevia tietoyhteyksiä, joiden avulla sovellukset vaihtavat tietoa keskenään. Tärkeä osa kokonaisarkkitehtuurin tunnistamista'
    },
    {
        link: '/contracts',
        icon: faFileContract,
        title: 'Sopimukset',
        ingress: 'Tietohallinnon hallinnollista johtamista',
        description:
            'Sopimukset ovat tärkeä osa tietohallinnon johtamista. Sopimukset ovat paras tapa harjoittaa riskienhallintaa tietohallinnon näkökannalta'
    }
];

const MobileNavigation = () => {
    const history = useHistory();
    const { userProfile, loadingUserProfile, Logout } = useContext(AppContext);
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    const [navOpen, setNavOpen] = useState(false);
    const NavigationLinks = () => {
        return (
            <Links>
                {loadingUserProfile ? (
                    <SpinnerSmall />
                ) : userProfile.length > 0 ? (
                    <LoginLinkContainer to="/userProfilepage" onClick={() => setNavOpen(false)}>
                        <IconContainer>
                            <Icon icon={faUser} />
                        </IconContainer>
                        <LinkText navOpen={navOpen}>Profiili</LinkText>
                    </LoginLinkContainer>
                ) : (
                    <LoginLinkContainer
                        to="/"
                        onClick={() => {
                            Logout();
                            setNavOpen(false);
                            loginWithRedirect({
                                authorizationParams: {
                                    redirect_uri: window.location.origin,
                                    audience: 'https://mydigimap-back.azurewebsites.net/',
                                    scope: 'offline_access'
                                }
                            });
                        }}>
                        <IconContainer>
                            <Icon icon={faSignInAlt} />
                        </IconContainer>
                        <LinkText navOpen={navOpen}>Kirjaudu</LinkText>
                    </LoginLinkContainer>
                )}
                {routeList.map((item, i) => {
                    return (
                        <LinkContainer key={i} to={item.link} onClick={() => setNavOpen(false)}>
                            {item.link === history.location.pathname ? (
                                <ActiveIconContainer>
                                    <ActiveIcon icon={item.icon} />
                                </ActiveIconContainer>
                            ) : (
                                <IconContainer>
                                    <Icon icon={item.icon} />
                                </IconContainer>
                            )}

                            <LinkText navOpen={navOpen}>{item.title}</LinkText>
                        </LinkContainer>
                    );
                })}
                {loadingUserProfile ? (
                    <SpinnerSmall />
                ) : userProfile.length > 0 ? (
                    <div>
                        <LinkContainer to="/logs" onClick={() => setNavOpen(false)}>
                            {history.location.pathname === '/logs' ? (
                                <ActiveIconContainer>
                                    <ActiveIcon icon={faClipboardList} />
                                </ActiveIconContainer>
                            ) : (
                                <IconContainer>
                                    <Icon icon={faClipboardList} />
                                </IconContainer>
                            )}

                            <LinkText navOpen={navOpen}>Tapahtumat</LinkText>
                        </LinkContainer>

                        {userProfile[0].roles != null &&
                        userProfile[0].roles.length > 0 &&
                        userProfile[0].roles[0].id === 1 ? (
                            <LinkContainer to="/settings" onClick={() => setNavOpen(false)}>
                                {history.location.pathname === '/settings' ? (
                                    <ActiveIconContainer>
                                        <ActiveIcon icon={faCog} />
                                    </ActiveIconContainer>
                                ) : (
                                    <IconContainer>
                                        <Icon icon={faCog} />
                                    </IconContainer>
                                )}
                                <LinkText navOpen={navOpen}>Organisaatio</LinkText>
                            </LinkContainer>
                        ) : null}
                    </div>
                ) : null}
            </Links>
        );
    };
    let path = useLocation();

    useEffect(() => {
        NavigationLinks();
    }, [path]);
    return (
        <div>
            <TopBar>
                <LogoContainer>
                    <LogoImage src="/images/logo.svg" />
                </LogoContainer>
                <BarsIconContainer>
                    <BarsIcon onClick={() => setNavOpen(!navOpen)}>
                        {!navOpen && <Icon icon={faBars} />}
                        {navOpen && <Icon icon={faTimes} />}
                    </BarsIcon>
                </BarsIconContainer>
            </TopBar>

            <Wrapper open={navOpen}>{NavigationLinks()}</Wrapper>
        </div>
    );
};

export default MobileNavigation;
