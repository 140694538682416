const theme = {
    dashboardColors: [
        '#FF8042',
        '#FFBB28',
        '#00C49F',
        '#0088FE',
        '#F84848',
        '#565E8E',
        '#FE45C6',
        '#31398A',
        '#FFBABA'
    ],
    colors: {
        brand_100: '#000B6E',
        brand_60: '#31398A',
        primary_120: '#0000AD',
        primary_110: '#0000DC',
        primary_100: '#0A22FF',
        primary_90: '#2030FF',
        primary_80: '#4754FF',
        primary_10: '#E6E6F7',
        primary_5: '#F2F2FD',
        success_50: '#B0E9A7',
        success_100: '#09A742',
        alert_50: '#FFBABA',
        alert_100: '#8E0000',
        warning_50: '#E1CE8C',
        warning_100: '#AB8500',
        white: '#FFFFFF',
        gray_10: '#FDFDFD',
        gray_20: '#FBFBFB',
        gray_30: '#F6F6F6',
        gray_40: '#F1F1F1',
        gray_50: '#E9E9E9',
        gray_60: '#DBDBDB',
        gray_80: '#BDBDBD',
        gray_100: '#444444',
        gray_120: '#282828',
        fontDark: '#252525',
        fontDarkLight: '#292929',
        notification_blue_50: '#EFF5FD',
        notification_blue_100: '#DEECFF',

        project_100: '#0099AE',
        project_50: '#EAB0099AE600',
        software_100: '#0011AA',
        software_50: '#0011AA',
        integration_100: '#EAB600',
        integration_50: '#EAB600',
        practice_100: '#1FB7E6',
        practice_50: '#1FB7E6',
        contract_100: '#B600BA',
        contract_50: '#B600BA',
        service_100: '#182B48',
        service_50: '#182B48',
        supplier_100: '#E891B3',
        supplier_50: '#E891B3',
        cost_100: '#FFBB94',
        cost_50: '#FFBB94'
    },
    fontWeight: {
        regular: '300',
        bold: '700'
    },
    grid: {
        divider_1: '8px',
        divider_2: '16px',
        divider_3: '24px',
        divider_4: '32px',
        divider_5: '40px',
        divider_6: '48px',
        divider_7: '56px',
        divider_8: '64px',
        divider_9: '72px',
        divider_10: '80px',
        divider_11: '88px',
        divider_12: '96px'
    }
};

export default theme;
