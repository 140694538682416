import React, { useEffect, useContext } from 'react';
import { AppContext } from '../../context/Context';
import { Redirect } from 'react-router-dom';
const events = ['load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress'];

const AppLogout = ({ children }) => {
    let timer;
    const { setIdle, Logout, userProfile, setUserProfile, setNotifyMessage } =
        useContext(AppContext);
    const handleLogoutTimer = () => {
        timer = setTimeout(() => {
            resetTimer();
            Object.values(events).forEach((item) => {
                window.removeEventListener(item, resetTimer);
            });
            logoutAction();
        }, 3600000); // 10000ms = 10secs 3600000 = 60mins
    };
    const resetTimer = () => {
        if (timer) clearTimeout(timer);
    };

    useEffect(() => {
        Object.values(events).forEach((item) => {
            window.addEventListener(item, () => {
                resetTimer();
                handleLogoutTimer();
            });
        });
    }, [userProfile]);
    const logoutAction = async () => {
        if (userProfile.length > 0) {
            Logout({ message: 'logout due idle time reached' });
            <Redirect to="/loginpage" />;
            setUserProfile([]);
            setIdle(true);
        }
        return true;
    };

    return children;
};

export default AppLogout;
