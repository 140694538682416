import React, { useState } from 'react';
import styled from 'styled-components';
import PulseLoader from 'react-spinners/PulseLoader';
import { device } from '../../device';

const SpinnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;

    @media ${device.laptop} {
        width: 100%;
    }
`;

const Title = styled.p`
    color: ${(props) => props.theme.colors.gray_50};
    margin-bottom: 20px;
`;

const SpinnerSmall = ({ title }) => {
    const [parentTitle, setParentTitle] = useState(title || '');
    return (
        <SpinnerContainer>
            <Title>{parentTitle || ''}</Title>
            <PulseLoader color="#BDBDBD" />
        </SpinnerContainer>
    );
};

export default SpinnerSmall;
