import React, { useState, useEffect, useRef } from 'react';
import { AppContext } from './Context';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Provider = ({ children }) => {
    const insert = async ({ path, params }) => {
        const token = localStorage.getItem('token');
        const sub = localStorage.getItem('sub');

        const compiledParams = () => {
            // const subId = Object.keys(user).length > 0 ? user.sub : 'No Auth0 user sub detected';
            let compilation = {};
            Object.assign(compilation, {
                sub: sub
            });
            Object.assign(compilation, params);

            return compilation;
        };
        try {
            const response = await axios.post(`/api/${path}`, {
                params: compiledParams(),
                token: token
            });
            if (response.data.success === true) {
                var filteredResponse =
                    response.data && response.data.data
                        ? response.data.data.filter((item) => {
                              return item != null;
                          })
                        : [];

                return {
                    data: {
                        success: true,
                        data: filteredResponse
                    }
                };
            } else {
                return {
                    data: {
                        success: false,
                        message: response.data.message || null,
                        data: []
                    }
                };
            }
        } catch (err) {
            console.log(err);
        }
    };

    const history = useHistory();
    const [firstLogin, setFirstLogin] = useState(false);
    const [noTeam, setNoTeam] = useState(false);
    const [auth0User, setAuth0User] = useState({});
    const [appLocation, setAppLocation] = useState('');
    const [loading, setLoading] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const [newElement, setNewElement] = useState(false);
    const [notifyMessage, setNotifyMessage] = useState('');
    const [roles, setRoles] = useState([]);
    const [users, setUsers] = useState([]);
    const [userProfile, setUserProfile] = useState([]);
    const [teamUser, setTeamUser] = useState([]);
    const [toggleComments, setToggleComments] = useState(true);
    const [toggleTasks, setToggleTasks] = useState(true);
    const [projectTasks, setProjectTasks] = useState([]);
    const [dashboardData, setDashboardData] = useState([]);
    const [teamMembers, setAssetMembers] = useState([]);
    const [referencedAssetLinks, setReferencedAssetLinks] = useState([]);
    const [apiRoutes, setApiRoutes] = useState([]);
    const [referencedTags, setReferencedTags] = useState([]);
    const [articles, setArticles] = useState([]);
    const [mapElements, setMapElements] = useState([]);
    const [mapElement, setMapElement] = useState([]);
    const [costs, setCosts] = useState([]);
    const [cost, setCost] = useState([]);
    const [integrations, setIntegrations] = useState([]);
    const [integration, setIntegration] = useState([]);
    const [softwares, setSoftwares] = useState([]);
    const [software, setSoftware] = useState([]);
    const [files, setFiles] = useState([]);
    const [notes, setNotes] = useState([]);
    const [contracts, setContracts] = useState([]);
    const [contract, setContract] = useState([]);
    const [categoriesList, setCategoriesList] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [practices, setPractices] = useState([]);
    const [practice, setPractice] = useState([]);
    const [projects, setProjects] = useState([]);
    const [project, setProject] = useState([]);
    const [logs, setLogs] = useState([]);
    const [newLogs, setNewLogs] = useState([]);
    const [services, setServices] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [notificationTypes, setNotificationTypes] = useState([]);
    const [activeTeam, setActiveTeam] = useState([]);
    const [teams, setTeams] = useState([]);

    // View togglers

    const [editSoftwaresTable, setEditSoftwaresTable] = useState(false);
    const [viewSoftwaresTable, setViewSoftwaresTable] = useState(true);
    const [viewSoftwaresCards, setViewSoftwaresCards] = useState(false);

    // Loaders
    const [loadingData, setLoadingData] = useState(false);
    const [loadingIntegrations, setLoadingIntegrations] = useState(false);
    const [loadingIntegration, setLoadingIntegration] = useState(false);
    const [loadingTasks, setLoadingTasks] = useState(false);
    const [loadingProjects, setLoadingProjects] = useState(false);
    const [loadingProject, setLoadingProject] = useState(false);
    const [loadingSuppliers, setLoadingSuppliers] = useState(false);
    const [loadingSupplier, setLoadingSupplier] = useState(false);
    const [loadingMapElements, setLoadingMapElements] = useState(false);
    const [loadingDashboard, setLoadingDashboard] = useState(false);
    const [loadingSoftware, setLoadingSoftware] = useState(false);
    const [loadingSoftwares, setLoadingSoftwares] = useState(false);
    const [loadingPractices, setLoadingPractices] = useState(false);
    const [loadingPractice, setLoadingPractice] = useState(false);
    const [loadingContracts, setLoadingContracts] = useState(false);
    const [loadingContract, setLoadingContract] = useState(false);
    const [loadingReferencedTags, setLoadingReferencedTags] = useState([]);
    const [loadingFiles, setLoadingFiles] = useState(false);
    const [loadingEmail, setLoadingEmail] = useState(false);
    const [loadingApiRoutes, setLoadingApiRoutes] = useState(false);
    const [loadingUserProfile, setLoadingUserProfile] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [loadingReferencedAssetLinks, setLoadingReferencedAssetLinks] = useState(false);
    const [loadingAssetMembers, setLoadingAssetMembers] = useState(false);
    const [loadingServices, setLoadingServices] = useState(false);

    const [loadingReferencesArray, setLoadingReferencesArray] = useState(false);
    const [loadingCost, setLoadingCost] = useState(false);
    const [loadingCosts, setLoadingCosts] = useState(false);
    const [loadingReference, setLoadingReference] = useState(false);
    const [loadingLists, setLoadingLists] = useState(false);
    const [loadingNotifications, setLoadingNotifications] = useState(false);
    const [loadingNotificationTypes, setLoadingNotificationTypes] = useState(false);
    const [loadingTagsList, setLoadingTagsList] = useState(false);
    const [loadingTeam, setLoadingTeam] = useState(false);
    const [loadingTeams, setLoadingTeams] = useState(false);
    const [loadingActiveTeam, setLoadingActiveTeam] = useState(false);
    const [loadingAuthentication, setLoadingAuthentication] = useState(false);
    // Loaders

    // references

    const [referencesArray, setReferencesArray] = useState([]);
    const [referencedSoftwares, setReferencedSoftwares] = useState([]);
    const [referencedContracts, setReferencedContracts] = useState([]);
    const [referencedIntegrations, setReferencedIntegrations] = useState([]);
    const [referencedProjects, setReferencedProjects] = useState([]);
    const [referencedPractices, setReferencedPractices] = useState([]);
    const [referencedCosts, setReferencedCosts] = useState([]);
    const [referencedSuppliers, setReferencedSuppliers] = useState([]);

    // references

    // static seeded data lists
    const [lists, setLists] = useState([]);
    const [securityCriticalnessList, setSecurityCriticalnessList] = useState([]);
    const [currenciesList, setCurrenciesList] = useState([]);
    const [userDataSensitivityList, setUserDataSensitivityList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [practiceCategories, setPracticeCategories] = useState([]);
    const [softwaresLocations, setSoftwaresLocations] = useState([]);
    const [lifecycleList, setLifecycleList] = useState([]);
    const [practiceMaturityList, setPracticeMaturityList] = useState([]);
    const [practicesList, setPracticesList] = useState([]);
    const [projectStatusList, setProjectStatusList] = useState([]);
    const [effectivenessList, setEffectivenessList] = useState([]);
    const [servicesList, setServicesList] = useState([]);
    const [softwaresList, setSoftwaresList] = useState([]);
    const [suppliersList, setSuppliersList] = useState([]);
    const [userFeatureRolesList, setUserFeatureRolesList] = useState([]);
    const [integrationTransferTypes, setIntegrationTransferTypes] = useState([]);
    const [integrationTypes, setIntegrationTypes] = useState([]);
    const [contractTerms, setContractTerms] = useState([]);
    const [contractTypes, setContractTypes] = useState([]);
    const [vatList, setVatList] = useState([]);
    const [billingFrequenciesList, setBillingFrequenciesList] = useState([
        {
            title: 'Kuukausittain'
        },
        {
            title: 'Kertakustannus'
        },
        {
            title: 'Vuosittain'
        }
    ]);

    // dashboard

    const [softwaresCount, setSoftwaresCount] = useState();
    const [softwaresTotalCost, setSoftwaresTotalCost] = useState();
    const [softwaresByLocation, setSoftwaresByLocation] = useState([]);
    const [softwaresLifecycle, setSoftwaresLifecycle] = useState([]);

    const [projectsCount, setProjectsCount] = useState();
    const [projectsCountByStatus, setProjectsCountByStatus] = useState([]);
    const [projectsTotalCost, setProjectsTotalCost] = useState();

    const [practicesCount, setPracticesCount] = useState();
    const [practicesCountByType, setPracticesCountByType] = useState([]);
    const [practiceMaturity, setPracticeMaturity] = useState([]);

    const [integrationsCount, setIntegrationsCount] = useState(0);
    const [integrationsTotalCost, setIntegrationsTotalCost] = useState();
    const [integrationsByType, setIntegrationsByType] = useState([]);

    const [contractsTotalCount, setContractsTotalCount] = useState(0);
    const [contractsCountByType, setContractsCountByType] = useState([]);
    const [contractsCountByExpirationDate, setContractsCountByExpirationDate] = useState();
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedTagsId, setSelectedTagsId] = useState([]);
    // dashboard

    // filters
    const [filters, setFilters] = useState([]);

    const InitialFilterData = () => {
        const filters = {
            softwares: [
                {
                    value: '',
                    filterGroup: 'softwares',
                    filterName: 'user_data_sensitivity',
                    defaultValue: 'Henkilötiedot',
                    options: userDataSensitivityList
                },
                {
                    value: '',
                    filterGroup: 'softwares',
                    filterName: 'location',
                    defaultValue: 'Tyyppi',
                    options: softwaresLocations
                },
                {
                    value: '',
                    filterGroup: 'softwares',
                    filterName: 'supplier',
                    defaultValue: 'Toimittaja',
                    options: suppliers
                },
                {
                    value: '',
                    filterGroup: 'softwares',
                    filterName: 'user',
                    defaultValue: 'Käyttäjä',
                    options: users
                },
                {
                    value: '',
                    filterGroup: 'softwares',
                    filterName: 'lifecycle',
                    defaultValue: 'Elinkaari',
                    options: lifecycleList
                },
                {
                    value: '',
                    filterGroup: 'softwares',
                    filterName: 'security_criticalness',
                    defaultValue: 'Kriittisyys',
                    options: securityCriticalnessList
                },
                {
                    value: '',
                    filterGroup: 'softwares',
                    filterName: 'practice',
                    defaultValue: 'Toiminto',
                    options: practices
                },
                {
                    value: '',
                    filterGroup: 'softwares',
                    filterName: 'effectiveness',
                    defaultValue: 'Vaikuttavuus',
                    options: effectivenessList
                }
            ],
            projects: [
                {
                    value: '',
                    filterGroup: 'projects',
                    filterName: 'status',
                    defaultValue: 'Tila',
                    options: projectStatusList
                },
                {
                    value: '',
                    filterGroup: 'projects',
                    filterName: 'practice',
                    defaultValue: 'Toiminto',
                    options: practices
                }
            ],
            practices: [
                {
                    value: '',
                    filterGroup: 'practices',
                    filterName: 'practice_type',
                    defaultValue: 'Tyyppi',
                    options: practiceCategories
                }
            ],
            integrations: [
                {
                    value: '',
                    filterGroup: 'integrations',
                    filterName: 'transfer_type',
                    defaultValue: 'Suunta',
                    options: integrationTransferTypes
                },
                {
                    value: '',
                    filterGroup: 'integrations',
                    filterName: 'type',
                    defaultValue: 'Tyyppi',
                    options: integrationTypes
                },
                {
                    value: '',
                    filterGroup: 'integrations',
                    filterName: 'supplier',
                    defaultValue: 'Toimittaja',
                    options: suppliers
                },
                {
                    value: '',
                    filterGroup: 'integrations',
                    filterName: 'start_software',
                    defaultValue: 'Lähtösovellus',
                    options: softwares
                },
                {
                    value: '',
                    filterGroup: 'integrations',
                    filterName: 'end_software',
                    defaultValue: 'Saapumissovellus',
                    options: softwares
                }
            ],

            contracts: [
                {
                    value: '',
                    filterGroup: 'contracts',
                    filterName: 'contract_type',
                    defaultValue: 'Sopimuksen tyyppi',
                    options: contractTypes
                }
            ]
        };
        setFilters(filters);
    };

    const getFilterValue = (category, filterName) => {
        const categoryFilters = filters[category] || [];
        if (categoryFilters.length > 0) {
            const filter = categoryFilters.find((item) => item.filterName === filterName);
            if (filter) {
                return filter.value;
            }
        }
        return '';
    };

    const resetFilters = (category) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            const categoryFilters = updatedFilters[category];
            if (categoryFilters) {
                categoryFilters.map((filter) => {
                    filter.value = '';
                });
            }
            return updatedFilters;
        });
    };
    const resetFilter = (category, filterName) => {
        setFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
            const categoryFilters = updatedFilters[category];
            if (categoryFilters) {
                const filter = categoryFilters.find((item) => item.filterName === filterName);
                if (filter) {
                    filter.value = '';
                }
            }
            return updatedFilters;
        });
    };
    const checkFilterValues = (category) => {
        const categoryFilters = filters[category];
        if (categoryFilters) {
            return categoryFilters.some((filter) => filter.value !== '');
        } else {
            return false;
        }
    };

    // filters

    // Delete functions

    const DeleteReference = async ({
        id,
        message,
        ref_id,
        ref_table,
        software_id,
        integration_id,
        contract_id,
        practice_id,
        project_id,
        cost_id,
        supplier_id
    }) => {
        try {
            const response = await insert({
                path: 'deleteReference',
                params: {
                    id: id || null
                }
            });
            let getRefTable = null;
            let refSoftware = null;
            let refIntegration = null;
            let refContract = null;
            let refProject = null;
            let refPractice = null;
            let refCost = null;
            let refSupplier = null;

            const getRefTableSetter = () => {
                if (cost_id != null) {
                    getRefTable = 'costs';
                } else if (software_id != null) {
                    getRefTable = 'softwares';
                } else if (integration_id != null) {
                    getRefTable = 'integrations';
                } else if (contract_id != null) {
                    getRefTable = 'contracts';
                } else if (project_id != null) {
                    getRefTable = 'projects';
                } else if (practice_id != null) {
                    getRefTable = 'practices';
                } else if (supplier_id != null) {
                    getRefTable = 'suppliers';
                } else {
                    console.log('no getRefTable set for fetching referenced data');
                }
            };
            const getRefIdSetter = () => {
                if (ref_table === 'costs') {
                    refCost = ref_id;
                } else if (ref_table === 'softwares') {
                    refSoftware = ref_id;
                } else if (ref_table === 'integrations') {
                    refIntegration = ref_id;
                } else if (ref_table === 'contracts') {
                    refContract = ref_id;
                } else if (ref_table === 'projects') {
                    refProject = ref_id;
                } else if (ref_table === 'practices') {
                    refPractice = ref_id;
                } else if (ref_table === 'suppliers') {
                    refSupplier = ref_id;
                } else {
                    console.log('no getRefTable set for fetching referenced data');
                }
            };
            if (response.data.success != false) {
                getRefIdSetter();
                getRefTableSetter();
                GetReferences({
                    ref_table: getRefTable || null,
                    software_id: refSoftware || null,
                    integration_id: refIntegration || null,
                    contract_id: refContract || null,
                    practice_id: refPractice || null,
                    project_id: refProject || null,
                    cost_id: refCost || null,
                    supplier_id: refSupplier || null
                });
            }
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        }
    };
    const DeleteReferenceRow = async ({ id, ref_table, practice_id, software_id, message }) => {
        try {
            const response = await insert({
                path: 'deleteReference',
                params: {
                    id: id || null
                }
            });
            if (response.data.success != false) {
                GetReferences({
                    ref_table: ref_table || null,
                    practice_id: practice_id || null,
                    software_id: software_id || null
                });
            }
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        }
    };
    const DeleteReferencedAssetLink = async ({ id }) => {
        try {
            const response = await insert({
                params: { id: id },
                path: 'deleteReferencedAssetLink'
            });
            GetReferencedAssetLinks();
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        }
    };

    const DeleteNotification = async ({ id, notification_type_id, ref_table, ref_id, user_id }) => {
        try {
            setLoadingNotifications(true);
            const response = await insert({
                params: {
                    id: id || null,
                    notification_type_id: notification_type_id || null,
                    ref_table: ref_table || null,
                    ref_id: ref_id || null,
                    user_id: user_id || null
                },
                path: 'deleteNotification'
            });
            GetNotifications({
                id: null,
                ref_table: ref_table || null,
                ref_id: ref_id || null,
                notification_type_id: notification_type_id || null
            });
            const messageBuilder = () => {
                if (response && response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Ilmoitukset poistettu käytöstä';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        } finally {
            setLoadingNotifications(false);
        }
    };

    const DeleteAssetMember = async ({ id }) => {
        try {
            const response = await insert({
                params: { id: id },
                path: 'deleteReferencedUserFeatureRole'
            });

            GetAssetMembers();
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Yhteys poistettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        }
    };
    const DeleteCost = async ({ id, ref_table, ref_id }) => {
        setLoading(true);
        try {
            const response = await insert({ params: { id: id }, path: 'deleteCost' });
            GetCosts({
                ref_table: null,
                ref_id: null
            });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        } finally {
            setLoading(false);
        }
    };
    const DeleteTag = async ({ id, tag_type }) => {
        setLoadingTagsList(true);
        try {
            const response = await insert({ params: { id: id }, path: 'deleteTag' });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    GetLists();
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        } finally {
            setLoadingTagsList(false);
        }
    };
    const DeleteNote = async ({ id }) => {
        setLoading(true);
        try {
            const response = await insert({ params: { id: id }, path: 'deleteNote' });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        } finally {
            setLoading(false);
        }
    };
    const DeleteMapElement = async ({ id }) => {
        setLoading(true);
        try {
            const response = await insert({ params: { id: id }, path: 'deleteMapElement' });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        } finally {
            setLoading(false);
        }
    };
    const DeleteFile = async ({ id, ref_table, ref_id }) => {
        setLoading(true);
        try {
            const response = await insert({ params: { id: id }, path: 'deleteFile' });
            GetFiles({ ref_table: ref_table, ref_id: ref_id });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoading(false);
        }
    };
    const DeleteSoftware = async ({ id }) => {
        try {
            const response = await insert({ params: { id: id }, path: 'deleteSoftware' });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Sovellus poistettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const DeleteSupplier = async ({ id }) => {
        try {
            const response = await insert({ params: { id: id }, path: 'deleteSupplier' });

            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    GetSuppliers();
                    return 'Toimittaja poistettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const DeletePractice = async (id) => {
        setLoading(true);
        try {
            const response = await insert({ params: { id: id }, path: 'deletePractice' });
            GetPractices({
                type: null,
                owners: null
            });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Toiminto poistettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const DeleteProject = async (id) => {
        try {
            const response = await insert({ params: { id: id }, path: 'deleteProject' });
            GetProjects({
                practice: null,
                owners: null,
                status: null
            });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Projekti poistettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const DeleteProjectTask = async ({ id, project_id }) => {
        setLoading(true);
        try {
            const response = await insert({ path: 'deleteTask', params: { id: id } });
            GetProjectTasks(project_id);
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoading(false);
        }
    };
    const DeleteIntegration = async (id) => {
        try {
            const response = await insert({ params: { id: id }, path: 'deleteIntegration' });
            GetIntegrations({
                transfer_type: null,
                type: null,
                owners: null,
                supplier: null,
                start_software: null,
                end_software: null
            });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Integraatio poistettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const DeleteUser = async ({ id }) => {
        setLoadingUsers(true);
        try {
            const response = await insert({ path: 'deleteUser', params: { id: id } });
            if (response.data.success != false) {
                GetTeamUsers();
                GetAssetMembers();
            }
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoadingUsers(false);
        }
    };
    const DeleteTeamUser = async (id) => {
        setLoadingUsers(true);
        try {
            const response = await insert({ path: 'deleteTeamUser', params: { id: id || null } });
            if (response.data.success != false) {
                GetTeamUsers();
                GetAssetMembers();
            }
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Poistaminen onnistui!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoadingUsers(false);
        }
    };

    const RevokeInvitation = async (id) => {
        setLoadingUsers(true);

        try {
            console.log(id);

            const response = await insert({
                path: 'revokeInvitation',
                params: {
                    // this should be team_user_id
                    id: id || null
                }
            });
            if (response.data.success != false) {
                GetTeamUsers();
                GetAssetMembers();
                setNotifyMessage('Käyttäjän käyttöoikeus ja kaikki kutsut palveluun on peruttu.');
            }
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Käyttäjän käyttöoikeus ja kaikki kutsut palveluun on peruttu.';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingUsers(false);
        }
    };
    const DeleteContract = async (id) => {
        try {
            const response = await insert({ params: { id: id }, path: 'deleteContract' });
            GetContracts({
                contract_terms: null,
                contract_type: null,
                id: null,
                integration: null,
                owners: null,
                project: null,
                software: null
            });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    history.push('/contracts');
                    return 'Sopimus poistettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };

    // Insert functions:
    const InsertMapElements = async ({
        title,
        connections,
        elements,
        nodes,
        ref_table,
        ref_id
    }) => {
        setLoadingMapElements(true);
        try {
            const response = await insert({
                path: 'insertMapElements',
                params: {
                    title: title || null,
                    ref_table: ref_table || null,
                    ref_id: ref_id || null,
                    connections: connections || null,
                    elements: elements || null,
                    nodes: nodes || null
                }
            });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Karttanäkymä tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
            setLoadingMapElements(false);
            GetMapElements();
        } catch (err) {
            console.log(err);
            setNotifyMessage('Karttanäkymän tallentaminen ei onnistunut.');
        } finally {
            setLoadingMapElements(false);
        }
    };
    const InsertIntegration = async ({
        start_software,
        end_software,
        title,
        info,
        lifecycle,
        supplier,
        transfer_type,
        type,
        id,
        additional_information
    }) => {
        try {
            const response = await insert({
                params: {
                    id: id || null,
                    start_software: start_software || null,
                    end_software: end_software || null,
                    title: title || null,
                    info: info || null,
                    lifecycle: lifecycle || null,
                    supplier: supplier || null,
                    transfer_type: transfer_type || null,
                    type: type || null,
                    additional_information: additional_information || null
                },
                path: 'insertIntegration'
            });
            setNewElement(true);
            if (response.data.success != false) {
                GetIntegrations({
                    transfer_type: null,
                    type: null,
                    owners: null,
                    supplier: null,
                    start_software: null,
                    end_software: null
                });
                InitialFilterData();
            }

            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Integraatio tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const InsertReferencedTag = async ({ tag_id, ref_table, ref_id, tag_type_id }) => {
        try {
            const response = await insert({
                path: 'insertReferencedTag',
                params: {
                    tag_id: tag_id || null,
                    ref_table: ref_table || null,
                    ref_id: ref_id || null,
                    tag_type_id: tag_type_id || null
                }
            });
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Tunniste tallennettu!';
                }
            };
            if (response.data.success != false) {
                GetReferencedTags();
                messageBuilder();
            }

            setNotifyMessage(messageBuilder());
            return response;
        } catch (err) {
            console.log(err);
        }
    };
    const InsertSoftware = async ({
        supplier,
        lifecycle,
        location,
        security_criticalness,
        info,
        title,
        id,
        user_data_sensitivity,
        additional_information,
        effectiveness,
        number_of_users,
        commissioning_day
    }) => {
        try {
            const response = await insert({
                params: {
                    id: id || null,
                    title: title || null,
                    supplier: supplier || null,
                    lifecycle: lifecycle || null,
                    security_criticalness: security_criticalness || null,
                    location: location || null,
                    info: info || null,
                    user_data_sensitivity: user_data_sensitivity || null,
                    additional_information: additional_information || null,
                    effectiveness: effectiveness || null,
                    number_of_users: number_of_users || null,
                    commissioning_day: commissioning_day || null
                },
                path: 'insertSoftware'
            });

            setNewElement(true);
            if (response.data.success != false) {
                GetSoftwares({
                    user_data_sensitivity: null,
                    supplier: null,
                    user: null,
                    location: null,
                    lifecycle: null,
                    effectiveness: null,
                    security_criticalness: null,
                    practice: null
                });
                InitialFilterData();
            }
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Sovellus tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
            return response;
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const InsertSupplier = async ({
        title,
        contact_person,
        phone,
        business_id,
        email,
        additional_information
    }) => {
        try {
            const response = await insert({
                params: {
                    title: title || null,
                    contact_person: contact_person || null,
                    phone: phone || null,
                    business_id: business_id || null,
                    email: email || null,
                    additional_information: additional_information || null
                },
                path: 'insertSupplier'
            });
            setNewElement(true);
            if (response.data.success != false) {
                GetSuppliers();
            }

            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Toimittaja tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const InsertEmail = async ({ subject, address, message }) => {
        try {
            setLoadingEmail(true);
            const response = await insert({
                path: 'insertEmail',
                params: {
                    address: address || null,
                    subject: subject || null,
                    message: message || null
                }
            });
            setNewElement(true);
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Kiitos viestistäsi! Palaamme asiaan mahdollisimman pian!';
                }
            };
            setNotifyMessage(messageBuilder());
            setLoadingEmail(false);
        } catch (err) {
            setNotifyMessage('Ikävä kyllä jokin meni pieleen. Yritä hetken kuluttua uudestaan.');
            setLoadingEmail(false);
            console.log(err);
        }
    };
    const InsertContract = async ({
        id,
        title,
        contract_type,
        contract_terms,
        info,
        start_date,
        end_date,
        supplier,
        software,
        project,
        owners,
        additional_information,
        term_of_notice
    }) => {
        try {
            const response = await insert({
                params: {
                    title: title || null,
                    contract_type: contract_type || null,
                    contract_terms: contract_terms || null,
                    info: info || null,
                    start_date: start_date || null,
                    end_date: end_date || null,
                    term_of_notice: term_of_notice || null,
                    supplier: supplier || null,
                    software: software || null,
                    project: project || null,
                    owners: owners || null,
                    id: id || null,
                    additional_information: additional_information || null
                },
                path: 'insertContract'
            });
            setNewElement(true);
            if (response.data.success != false) {
                GetContracts({
                    contract_terms: null,
                    contract_type: null,
                    id: null,
                    integration: null,
                    owners: null,
                    project: null,
                    software: null
                });
                InitialFilterData();
            }
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Sopimus tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };

    const InsertPractice = async ({
        title,
        owners,
        category,
        info,
        maturity,
        id,
        additional_information
    }) => {
        try {
            const response = await insert({
                params: {
                    title: title,
                    owners: owners,
                    category: category,
                    info: info,
                    maturity: maturity,
                    id: id,
                    additional_information: additional_information
                },
                path: 'insertPractice'
            });
            setNewElement(true);
            if (response.data.success != false) {
                GetPractices({
                    category: null,
                    owners: null
                });
                InitialFilterData();
            }

            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Toiminto tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };

    const InsertNotification = async ({
        notification_id,
        user_id,
        notification_type_id,
        ref_table,
        ref_id,
        frequency_settings
    }) => {
        try {
            setLoadingNotifications(true);
            const response = await insert({
                params: {
                    id: notification_id || null,
                    user_id: user_id || null,
                    notification_type_id: notification_type_id || null,
                    ref_table: ref_table || null,
                    ref_id: ref_id || null,
                    frequency_settings: frequency_settings || null
                },
                path: 'insertNotification'
            });
            if (response.data.success != false) {
                GetNotifications({
                    id: null,
                    user_id: null,
                    ref_table: ref_table || null,
                    ref_id: ref_id || null
                }),
                    setNewElement(true);
                setNotifyMessage('Ilmoitukset otettu käyttöön');
            } else {
                setNotifyMessage(
                    response.data.message ? response.data.message : 'Jokin meni pieleen'
                );
            }
            return response;
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoadingNotifications(false);
        }
    };

    const InsertUser = async ({ email, id, first_name, last_name }) => {
        setLoadingUsers(true);
        try {
            const response = await insert({
                params: {
                    id: id || null,
                    first_name: first_name || null,
                    last_name: last_name || null,
                    email: email || null
                },
                path: 'insertUser'
            });
            if (response.data.success != false) {
                GetTeamUsers();
                GetAssetMembers();
                setNewElement(true);
                setNotifyMessage('Käyttäjän tiedot tallennettu!');
                GetUserProfile();
                return response.data;
            } else {
                setNotifyMessage(
                    response.data.message ? response.data.message : 'Jokin meni pieleen'
                );
            }
            setLoadingUsers(false);
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoadingUsers(false);
        }
    };
    const InsertTeamUser = async ({ email, sub, id, first_name, last_name, role }) => {
        setLoadingUsers(true);
        try {
            const response = await insert({
                params: {
                    id: id || null,
                    first_name: first_name || null,
                    last_name: last_name || null,
                    email: email || null,
                    role: role || null
                },
                path: 'insertTeamUser'
            });
            if (response.data.success != false) {
                GetTeamUsers();
                // GetUserProfile({ id: response.data.data[0].id || null });
                GetAssetMembers();
                setNewElement(true);
                setNotifyMessage('Käyttäjä tallennettu!');
                return response.data;
            } else {
                setNotifyMessage(
                    response.data.message ? response.data.message : 'Jokin meni pieleen'
                );
            }
            setLoadingUsers(false);
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoadingUsers(false);
        }
    };
    const InviteUser = async ({ email, role, id }) => {
        setLoadingUsers(true);
        try {
            const response = await insert({
                params: {
                    id: id || null,
                    role: role || null,
                    email: email || null
                },
                path: 'inviteUser'
            });

            if (response.data.success != false) {
                GetTeamUsers();
                setNewElement(true);
                setNotifyMessage('Kutsu lähetetty!');
            } else {
                setNotifyMessage(
                    response.data.message ? response.data.message : 'Jokin meni pieleen'
                );
            }
            setLoadingUsers(false);
        } catch (err) {
            console.log(err);
            setNotifyMessage('Jokin meni pieleen');
        } finally {
            setLoadingUsers(false);
        }
    };
    const InsertProjectTask = async ({ id, project_id, title, status }) => {
        try {
            const response = await insert({
                path: 'insertTask',
                params: {
                    id: id || null,
                    projectID: project_id || null,
                    title: title || null,
                    status: status || null
                }
            });
            setNewElement(true);
            if (response.data.success != false) {
                GetProjectTasks();
            }

            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Tehtävä tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const InsertProject = async ({
        practice_id,
        title,
        start_date,
        end_date,
        status,
        category,
        info,
        owners,
        cost,
        id,
        practice,
        additional_information
    }) => {
        try {
            const response = await insert({
                params: {
                    practice: practice_id || null,
                    title: title || null,
                    status: status || null,
                    info: info || null,
                    start_date: start_date || null,
                    end_date: end_date || null,
                    category: category || null,
                    owners: owners || null,
                    cost: cost || null,
                    id: id || null,
                    additional_information: additional_information || null
                },
                path: 'insertProject'
            });
            setNewElement(true);
            if (response.data.success != false) {
                GetProjects({
                    practice: practice || null,
                    owners: null,
                    status: null
                });
                InitialFilterData();
            }

            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Projekti tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const InsertAssetMember = async ({ ref_id, ref_table, user_feature_role, user }) => {
        try {
            const response = await insert({
                params: {
                    ref_id: ref_id || null,
                    ref_table: ref_table || null,
                    user_feature_role: user_feature_role || null,
                    user: user || null
                },
                path: 'insertReferencedUserFeatureRole'
            });
            setNewElement(true);
            if (response.data.success != false) {
                GetAssetMembers();
            }

            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Käyttäjä tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const InsertReferencedAssetLink = async ({
        ref_id,
        ref_table,
        referenced_asset_link,
        info
    }) => {
        try {
            const response = await insert({
                params: {
                    ref_id: ref_id || null,
                    ref_table: ref_table || null,
                    referenced_asset_link: referenced_asset_link || null,
                    info: info || null
                },
                path: 'insertReferencedAssetLinks'
            });
            setNewElement(true);
            if (response.data.success != false) {
                GetReferencedAssetLinks();
            }

            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return 'Linkki tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };
    const InsertActiveTeam = async ({ id }) => {
        try {
            const response = await insert({
                path: 'insertActiveTeam',
                params: {
                    id: id || null
                }
            });
            if (response.data.success === true) {
                history.push('/dashboardpage');
                AuthCheck();
            }
        } catch (err) {
            console.log(err);
        }
    };

    const InsertReference = async ({
        ref_table,
        ref_id,
        cost_id,
        software_id,
        integration_id,
        contract_id,
        practice_id,
        project_id,
        message,
        supplier_id,
        service_id,
        id
    }) => {
        try {
            const RefIdSetter = () => {
                if (ref_table === 'costs') {
                    refCost = ref_id;
                } else if (ref_table === 'softwares') {
                    refSoftware = ref_id;
                } else if (ref_table === 'integrations') {
                    refIntegration = ref_id;
                } else if (ref_table === 'contracts') {
                    refContract = ref_id;
                } else if (ref_table === 'projects') {
                    refProject = ref_id;
                } else if (ref_table === 'practices') {
                    refPractice = ref_id;
                } else if (ref_table === 'suppliers') {
                    refSupplier = ref_id;
                } else {
                    console.log('no ref_table in use for fetching references');
                }
            };

            let getRefTable = null;
            let refCost = '';
            let refSoftware = '';
            let refIntegration = '';
            let refContract = '';
            let refProject = '';
            let refPractice = '';
            let refSupplier = '';
            let refService = '';

            RefIdSetter();
            const response = await insert({
                // NOTE if sending empty string, no UPDATE will happen in the backend. Only sending NULL or STRING with data will make update
                params: {
                    id: id || null,
                    software_id: software_id != null ? software_id : refSoftware,
                    integration_id: integration_id != null ? integration_id : refIntegration,
                    contract_id: contract_id != null ? contract_id : refContract,
                    project_id: project_id != null ? project_id : refProject,
                    practice_id: practice_id != null ? practice_id : refPractice,
                    cost_id: cost_id != null ? cost_id : refCost,
                    supplier_id: supplier_id != null ? supplier_id : refSupplier,
                    service_id: service_id != null ? service_id : refService
                },
                path: 'insertReference'
            });

            const getRefTableSetter = () => {
                if (cost_id != null) {
                    getRefTable = 'costs';
                } else if (software_id != null) {
                    getRefTable = 'softwares';
                } else if (integration_id != null) {
                    getRefTable = 'integrations';
                } else if (contract_id != null) {
                    getRefTable = 'contracts';
                } else if (project_id != null) {
                    getRefTable = 'projects';
                } else if (practice_id != null) {
                    getRefTable = 'practices';
                } else if (supplier_id != null) {
                    getRefTable = 'suppliers';
                } else {
                    console.log('no getRefTable set for fetching referenced data');
                }
            };

            if (response.data.success != false) {
                getRefTableSetter();
                GetReferences({
                    ref_table: getRefTable || null,
                    software_id: refSoftware || null,
                    integration_id: refIntegration || null,
                    contract_id: refContract || null,
                    practice_id: refPractice || null,
                    project_id: refProject || null,
                    cost_id: refCost || null,
                    supplier_id: refSupplier || null
                });
            }
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return message || 'Yhteys tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
            return response;
        } catch (err) {
            console.log(err, 'problem with inserting reference');
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        }
    };

    const InsertCost = async ({ cost, billing_frequency, id, currency, title, vat, message }) => {
        try {
            const response = await insert({
                params: {
                    billing_frequency: billing_frequency || null,
                    cost: cost || null,
                    id: id || null,
                    vat: vat || null,
                    currency: currency || null,
                    title: title || null
                },
                path: 'insertCost'
            });
            setNewElement(true);
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    return message || 'Kustannus tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());

            return response;
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!', err);
        }
    };

    const InsertTag = async ({ tag_type_id, title, id }) => {
        setLoadingTagsList(true);
        try {
            const response = await insert({
                path: 'insertTag',
                params: {
                    id: id || null,
                    tag_type_id: tag_type_id,
                    title: title
                }
            });
            setNewElement(true);
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    GetLists();
                    return response.data.message || 'Tunniste tallennettu!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoadingTagsList(false);
        }
    };

    // Get functions

    const GetNotifications = async ({ id, user_id, ref_table, ref_id }) => {
        try {
            setLoadingNotifications(true);
            const response = await insert({
                params: {
                    id: id || null,
                    user_id: user_id || null,
                    ref_table: ref_table || null,
                    ref_id: ref_id || null
                },
                path: 'getNotifications'
            });

            if (response.data.success === true) {
                setNotifications(response.data.data);
                setLoadingNotifications(false);
            }
        } catch (err) {
            console.log('Error with fetching notifications', err);
            setLoadingNotifications(false);
        } finally {
            setLoadingNotifications(false);
        }
    };
    const GetNotificationTypes = async ({ id, ref_table }) => {
        try {
            setLoadingNotificationTypes(true);
            const response = await insert({
                params: {
                    id: id || null,
                    ref_table: ref_table || null
                },
                path: 'getNotificationTypes'
            });

            if (response.data.success === true) {
                setNotificationTypes(response.data.data);
                setLoadingNotificationTypes(false);
            }
        } catch (err) {
            console.log('Error with fetching notification types', err);
        } finally {
            setLoadingNotificationTypes(false);
        }
    };

    const GetDashboardData = async () => {
        setLoadingDashboard(true);
        try {
            const dashboardDataFromApi = await insert({ path: 'dashboard', params: null });
            const data = dashboardDataFromApi.data.data[0];
            if (
                dashboardDataFromApi.data &&
                dashboardDataFromApi.data.data &&
                Array.isArray(dashboardDataFromApi.data.data) &&
                dashboardDataFromApi.data.data.length > 0
            ) {
                setSoftwaresCount((data.softwares_count && data.softwares_count.value) || 0);
                setSoftwaresTotalCost(
                    (data.softwares_total_cost && data.softwares_total_cost.value) || 0
                );
                setSoftwaresByLocation(
                    (data.softwares_by_location && data.softwares_by_location) || null
                );
                setSoftwaresLifecycle(
                    (data.softwares_lifecycle && data.softwares_lifecycle) || null
                );

                setProjectsCount((data.projects_count && data.projects_count.value) || 0);
                setProjectsCountByStatus(
                    (data.projects_count_by_status && data.projects_count_by_status) || null
                );
                setProjectsTotalCost(
                    (data.projects_total_cost && data.projects_total_cost.value) || 0
                );

                setPracticesCount((data.practices_count && data.practices_count.value) || 0);
                setPracticesCountByType(data.practices_count_by_type || 0);
                setPracticeMaturity(data.practice_maturity || 0);

                setIntegrationsCount(
                    (data.integrations_count && data.integrations_count.value) || 0
                );
                setIntegrationsTotalCost(
                    (data.integrations_total_cost && data.integrations_total_cost.value) || 0
                );
                setIntegrationsByType(data.integrations_by_type || 0);

                setContractsTotalCount(
                    (data.contracts_total_count && data.contracts_total_count.value) || 0
                );
                setContractsCountByType(data.contracts_count_by_type || 0);
                setContractsCountByExpirationDate(
                    (data.contracts_count_by_expiration_date &&
                        data.contracts_count_by_expiration_date.value.length) ||
                        0
                );
            }
            setLoadingDashboard(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingDashboard(false);
        }
    };

    const GetLists = async () => {
        try {
            setLoadingLists(true);
            const response = await insert({
                path: 'getLists',
                params: null
            });

            if (response.data.success === true) {
                setLists(response.data.data[0]);
                setLoadingLists(false);
            }
        } catch (err) {
            console.log('Error with fetching lists', err);
        } finally {
            setLoadingLists(false);
        }
    };

    const GetReferencedTags = async () => {
        setLoadingReferencedTags(true);
        try {
            const response = await insert({
                path: 'getReferencedTags',
                params: {
                    ref_table: null,
                    ref_id: null,
                    tag_type_id: null
                }
            });
            setReferencedTags(response.data.data);
            setLoadingReferencedTags(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingReferencedTags(false);
        }
    };
    const GetMapElements = async () => {
        setLoadingMapElements(true);
        try {
            const elements = await insert({ path: 'getMapElements', params: null });
            setMapElements(elements.data.data);
            setLoadingMapElements(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingMapElements(false);
        }
    };
    const GetMapElement = async ({ id }) => {
        setLoadingMapElements(true);
        try {
            const elements = await insert({
                path: 'getMapElements',
                params: {
                    id: id || null
                }
            });
            setMapElement(elements.data.data);
            setLoadingMapElements(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingMapElements(false);
        }
    };
    const GetCosts = async () => {
        setLoadingCosts(true);
        try {
            const response = await insert({
                path: 'getCosts'
            });
            setCosts(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingCosts(false);
        }
    };
    const GetCost = async (id) => {
        setLoadingCost(true);
        try {
            const response =
                costs.length > 0 ? costs.filter((item) => JSON.stringify(item.id) === id) : [];
            setCost(response && response.length > 0 ? response[0] : []);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingCost(false);
        }
    };

    const GetApiRoutes = async () => {
        try {
            setLoadingApiRoutes(true);
            const response = await insert({
                path: 'getApiRoutes',
                params: null
            });
            if (response.data.success === true) {
                setApiRoutes(response.data.data);
                setLoadingApiRoutes(false);
            }
        } catch (err) {
            console.log('Error with fetching api routes', err);
        } finally {
            setLoadingApiRoutes(false);
        }
    };
    const GetReferencedAssetLinks = async () => {
        try {
            setLoadingReferencedAssetLinks(true);
            const response = await insert({
                path: 'getReferencedAssetLinks',
                params: null
            });

            if (response.data.success === true) {
                setReferencedAssetLinks(response.data.data);
                setLoadingReferencedAssetLinks(false);
            }
        } catch (err) {
            console.log('Error with fetching referenced asset links', err);
        } finally {
            setLoadingReferencedAssetLinks(false);
        }
    };
    const GetAssetMembers = async () => {
        try {
            setLoadingAssetMembers(true);
            const response = await insert({
                path: 'getReferencedUserFeatureRoles',
                params: null
            });

            if (response.data.success === true) {
                setAssetMembers(response.data.data);
                setLoadingAssetMembers(false);
            }
        } catch (err) {
            console.log('Error with fetching referenced user feature roles', err);
        } finally {
            setLoadingAssetMembers(false);
        }
    };
    const GetReferences = async ({
        ref_table,
        ref_id,
        software_id,
        integration_id,
        contract_id,
        practice_id,
        project_id,
        cost_id,
        supplier_id
    }) => {
        setLoading(true);
        try {
            const response = await insert({
                path: 'getReferences',
                params: {
                    // ref_table defines the table from witch data will be fetched
                    // software_id etc. defines the software that the fetched data will refer to

                    ref_table: ref_table || null,
                    ref_id: ref_id || null,
                    software_id: software_id || null,
                    integration_id: integration_id || null,
                    contract_id: contract_id || null,
                    practice_id: practice_id || null,
                    project_id: project_id || null,
                    cost_id: cost_id || null,
                    supplier_id: supplier_id || null
                }
            });
            if (ref_table == 'softwares') {
                setReferencedSoftwares(response.data.data);
            } else if (ref_table == 'contracts') {
                setReferencedContracts(response.data.data);
            } else if (ref_table == 'practices') {
                setReferencedPractices(response.data.data);
            } else if (ref_table == 'integrations') {
                setReferencedIntegrations(response.data.data);
            } else if (ref_table == 'projects') {
                setReferencedProjects(response.data.data);
            } else if (ref_table == 'costs') {
                setReferencedCosts(response.data.data);
            } else if (ref_table == 'suppliers') {
                setReferencedSuppliers(response.data.data);
            } else {
                console.log('no ref_table set for fetching referenced data');
            }
        } catch (err) {
            console.log(err);
            setNotifyMessage('Jokin meni pieleen!');
        } finally {
            setLoading(false);
        }
    };
    const GetReference = async ({
        ref_table,
        ref_id,
        software_id,
        integration_id,
        contract_id,
        practice_id,
        project_id,
        cost_id,
        supplier_id
    }) => {
        setLoadingReference(true);
        try {
            const response = await insert({
                path: 'getReferences',
                params: {
                    ref_table: ref_table || null,
                    ref_id: ref_id || null,
                    software_id: software_id || null,
                    integration_id: integration_id || null,
                    contract_id: contract_id || null,
                    practice_id: practice_id || null,
                    project_id: project_id || null,
                    cost_id: cost_id || null,
                    supplier_id: supplier_id || null
                }
            });
            return response;
        } catch (err) {
            console.log(err);
            setNotifyMessage('Jokin meni pieleen!');
        } finally {
            setLoadingReference(false);
        }
    };

    const GetLogs = async () => {
        setLoading(true);
        try {
            const response = await insert({ path: 'getLogs', params: null });
            setLogs(response.data.data);
        } catch (err) {
            console.log('Fetching logs failed:', err);
        } finally {
            setLoading(false);
        }
    };
    const GetNewLogs = async () => {
        setLoading(true);
        try {
            const response = await insert({ path: 'getNewLogs', params: null });
            setNewLogs(response.data.data);
        } catch (err) {
            console.log('Fetching new logs failed:', err);
        } finally {
            setLoading(false);
        }
    };

    const GetFiles = async ({ ref_id, ref_table }) => {
        setLoadingFiles(true);
        try {
            const response = await insert({
                path: 'getFiles',
                params: {
                    ref_table: ref_table,
                    ref_id: ref_id
                }
            });
            setFiles(response.data.data);
            setLoadingFiles(false);
        } catch (err) {
            console.log('Fetching files failed:', err);
        } finally {
            setLoadingFiles(false);
        }
    };

    const GetIntegration = async (id) => {
        setLoadingIntegration(true);
        try {
            const response =
                integrations.length > 0
                    ? integrations.filter((item) => JSON.stringify(item.id) === id)
                    : [];
            setIntegration(response && response.length > 0 ? response[0] : []);
            setLoadingIntegration(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingIntegration(false);
        }
    };
    const GetIntegrations = async ({
        transfer_type,
        type,
        supplier,
        start_software,
        end_software,
        tags
    }) => {
        setLoadingIntegrations(true);
        try {
            const response = await insert({
                params: {
                    transfer_type: transfer_type || null,
                    type: type || null,
                    owners: null,
                    supplier: supplier || null,
                    start_software: start_software || null,
                    end_software: end_software || null,
                    tags: tags && tags.length > 0 ? tags : null
                },
                path: 'getIntegrations'
            });
            setIntegrations(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingIntegrations(false);
        }
    };
    const GetSuppliers = async () => {
        setLoadingSuppliers(true);
        try {
            const response = await insert({ path: 'getSuppliers', params: null });
            setSuppliers(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingSuppliers(false);
        }
    };

    const GetProject = async (id) => {
        setLoadingProject(true);
        try {
            const response =
                projects.length > 0
                    ? projects.filter((item) => JSON.stringify(item.id) === id)
                    : [];
            setProject(response && response.length > 0 ? response[0] : []);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingProject(false);
        }
    };
    const GetProjects = async ({ practice, owners, status }) => {
        setLoadingProjects(true);
        try {
            const response = await insert({
                path: 'getProjects',
                params: {
                    id: null,
                    practice: practice || null,
                    owners: owners || null,
                    status: status || null
                }
            });
            setProjects(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingProjects(false);
        }
    };

    const GetPractice = async (id) => {
        setLoadingPractice(true);
        try {
            const response =
                practices.length > 0
                    ? practices.filter((item) => JSON.stringify(item.id) === id)
                    : [];
            setPractice(response && response.length > 0 ? response[0] : []);
            setLoadingPractice(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingPractice(false);
        }
    };
    const GetArticles = async () => {
        try {
            const response = await insert({ path: 'getArticles', params: null });
            setArticles(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    const GetPractices = async ({ category }) => {
        setLoadingPractices(true);
        try {
            const response = await insert({
                path: 'getPractices',
                params: {
                    id: null,
                    owners: null,
                    category: category || null
                }
            });
            setPractices(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingPractices(false);
        }
    };
    const GetSoftwares = async ({
        user_data_sensitivity,
        supplier,
        user,
        location,
        lifecycle,
        effectiveness,
        security_criticalness,
        practice,
        tags
    }) => {
        setLoadingSoftwares(true);
        try {
            const response = await insert({
                params: {
                    id: null,
                    user_data_sensitivity: user_data_sensitivity || null,
                    location: location || null,
                    supplier: supplier || null,
                    user: user || null,
                    lifecycle: lifecycle || null,
                    effectiveness: effectiveness || null,
                    security_criticalness: security_criticalness || null,
                    practice: practice || null,
                    tags: tags && tags.length > 0 ? tags : null
                },
                path: 'getSoftwares'
            });
            setSoftwares(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingSoftwares(false);
        }
    };
    const GetServices = async () => {
        setLoadingServices(true);
        try {
            const response = await insert({
                path: 'getServices'
            });
            setServices(response.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingServices(false);
        }
    };
    const GetSoftware = async (id) => {
        setLoadingSoftware(true);
        try {
            const response =
                softwares.length > 0
                    ? softwares.filter((item) => JSON.stringify(item.id) === id)
                    : [];
            setSoftware(response && response.length > 0 ? response[0] : []);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingSoftware(false);
        }
    };
    const GetSupplier = async (id) => {
        setLoadingSupplier(true);
        try {
            const response =
                suppliers.length > 0
                    ? suppliers.filter((item) => JSON.stringify(item.id) === id)
                    : [];
            setSupplier(response && response.length > 0 ? response[0] : []);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingSupplier(false);
        }
    };
    const GetContracts = async ({
        contract_terms,
        contract_type,
        id,
        integration,
        owners,
        project,
        software
    }) => {
        setLoadingContracts(true);
        try {
            const response = await insert({
                params: {
                    contract_terms: contract_terms || null,
                    contract_type: contract_type || null,
                    id: id || null,
                    integration: integration || null,
                    owners: owners || null,
                    project: project || null,
                    software: software || null
                },
                path: 'getContracts'
            });
            setContracts(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingContracts(false);
        }
    };
    const GetContract = async (id) => {
        setLoadingContract(true);
        try {
            const response =
                contracts.length > 0
                    ? contracts.filter((item) => JSON.stringify(item.id) === id)
                    : [];
            setContract(response && response.length > 0 ? response[0] : []);
            setLoadingContract(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingContract(false);
        }
    };

    const GetNotes = async ({ ref_table, ref_id }) => {
        setLoading(true);
        try {
            const response = await insert({
                path: 'getNotes',
                params: {
                    table: ref_table,
                    ref_id: ref_id
                }
            });
            setNotes(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const GetReferencesArray = async ({ ref_id, ref_table, id }) => {
        setLoadingReferencesArray(true);
        try {
            const response = await insert({
                params: {
                    id: id || null,
                    ref_id: ref_id || null,
                    ref_table: ref_table || null
                },
                path: 'getReferencesArray'
            });
            setReferencesArray(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingReferencesArray(false);
        }
    };

    const GetProjectTasks = async () => {
        setLoadingTasks(true);
        try {
            const response = await insert({ path: 'getTasks', params: null });
            setProjectTasks(response.data.data);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingTasks(false);
        }
    };
    const GetTeamUsers = async () => {
        setLoadingUsers(true);
        try {
            const response = await insert({
                path: 'getTeam',
                params: { id: activeTeam.length > 0 ? activeTeam[0].id : null }
            });
            if (response.data.success === true) {
                setUsers(response.data.data[0].users);
            }

            setLoadingUsers(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingUsers(false);
        }
    };
    const GetTeamUser = async (id) => {
        setLoadingUsers(true);
        try {
            const response =
                users.length > 0 ? users.filter((item) => JSON.stringify(item.id) === id) : [];
            setTeamUser(response && response.length > 0 ? response : []);
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingUsers(false);
        }
    };

    const GetTeams = async () => {
        setLoadingTeams(true);
        try {
            const response = await insert({ path: 'getTeams', params: null });
            setTeams(response.data.data);
            return response.data.data;
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingTeams(false);
        }
    };
    // Team API calls

    const InsertTeam = async ({ id, title }) => {
        try {
            const response = await insert({
                params: {
                    id: id || null,
                    title: title || null
                },
                path: 'insertTeam'
            });
            setNewElement(true);
            const messageBuilder = () => {
                if (response.data.success === false) {
                    return response.data.message;
                } else {
                    GetLists();
                    return response.data.message || 'Tiedot päivitetty!';
                }
            };
            setNotifyMessage(messageBuilder());
        } catch (err) {
            console.log(err);
            setNotifyMessage('Ikävä kyllä jokin meni pieleen!');
        } finally {
            setLoadingTagsList(false);
        }
    };

    const PushToRoute = () => {
        if (localStorage.getItem('path') === null) {
            history.push('/');
        } else {
            const path = localStorage.getItem('path');
            history.push(path);
        }
    };
    const SetLocationPath = () => {
        const path = localStorage.getItem('path') || '/';
        !isAuthenticated ? localStorage.setItem('path', '/') : null;
        if (
            history.location.pathname === '/userProfilepage' ||
            history.location.pathname === '/settings'
        ) {
            localStorage.setItem('path', path);
        } else {
            localStorage.setItem('path', history.location.pathname);
        }
    };

    const {
        getAccessTokenSilently,
        getTokenWithPopup,
        loginWithRedirect,
        logout,
        user,
        isLoading,
        isAuthenticated
    } = useAuth0();

    const GetUserProfile = async () => {
        setLoadingUserProfile(true);

        try {
            const response = await insert({
                params: null,
                path: 'getUser'
            });
            if (response.data.success === true) {
                let data = response.data.data[0];
                setUserProfile([
                    {
                        id: data.id,
                        email: data.email || '',
                        first_name: data.first_name || '',
                        last_name: data.last_name || '',
                        roles: data.roles ? data.roles : [],
                        permissions: data.permissions ? data.permissions : [],
                        sub: '',
                        teams: data.teams ? data.teams : [],
                        activeTeam: data.activeTeam || []
                    }
                ]);
                // setTeams(response.data.data[0].teams || []);
                // setActiveTeam(
                //     response.data.data && response.data.data[0].active_team.length > 0
                //         ? response.data.data[0].active_team
                //         : []
                // );
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingUserProfile(false);
        }
    };
    const FirstLoginCheck = async () => {
        try {
            const response = await insert({
                params: null,
                path: 'getUser'
            });
            if (response.data.success === true) {
                if (response.data.data[0].first_login === true) {
                    setFirstLogin(true);
                    console.log('firstLogin set to:', response.data.data[0].first_login);
                } else {
                    let data = response.data.data[0];
                    // setFirstLogin(false)
                    setUserProfile([
                        {
                            id: data.id,
                            email: data.email || '',
                            first_name: data.first_name || '',
                            last_name: data.last_name || '',
                            roles: data.roles ? data.roles : [],
                            permissions: data.permissions ? data.permissions : [],
                            sub: '',
                            teams: data.teams ? data.teams : [],
                            activeTeam: data.activeTeam || []
                        }
                    ]);
                    setTeams(response.data.data[0].teams || []);
                    // if (response.data.data[0].teams.length > 0 || undefined) {
                    //     Logout();
                    // }
                    setActiveTeam(
                        response.data.data && response.data.data[0].active_team.length > 0
                            ? response.data.data[0].active_team
                            : []
                    );
                }
                // }
            }
            if (response.data.success === false) {
                // Logout();

                setNotifyMessage(
                    'Ongelmia kirjautumisen kanssa. Jos ongelma jatkuu ota yhteyttä support@mydigimap.com.'
                );
            }
        } catch (err) {
            console.log('Error with first login check:', err);
        }
    };

    const Logout = async (message) => {
        setLoadingUserProfile(true);
        try {
            localStorage.clear();
            setFirstLogin(false);
            setAuth0User();
            setTeams([]);
            setActiveTeam([]);
            setUserProfile([]);
            await logout({ logoutParams: { returnTo: window.location.origin } });
        } catch (err) {
            console.log(err);
        } finally {
            if (message) {
                sessionStorage.setItem('logout_message', message.message);
            }
            setLoadingUserProfile(false);
        }
    };
    const AuthCheck = async () => {
        setLoadingAuthentication(true);

        try {
            await SaveUserData();
            await SaveAccessToken();
            await SaveUserSub();
        } catch (err) {
            console.log(err);
        } finally {
            setLoadingAuthentication(false);
        }
    };

    const SaveAccessToken = async () => {
        try {
            const newAccessToken = await getAccessTokenSilently({
                authorizationParams: {
                    audience: 'https://mydigimap-back.azurewebsites.net/',
                    scope: 'offline_access'
                }
            });
            localStorage.setItem('token', newAccessToken || 'no access token found');
            if (localStorage.getItem('token') === 'no access token found') null;
        } catch (err) {
            console.log(err);
        }
    };

    const SaveUserSub = async () => {
        try {
            const newUserSub = user ? user.sub : 'no user sub found';
            localStorage.setItem('sub', newUserSub);
            if (localStorage.getItem('sub') === 'no user sub found') null;
            else {
                FirstLoginCheck();
            }
        } catch (err) {
            console.log(err);
        }
    };
    const SaveUserData = async () => {
        try {
            const userData = user || {};
            if (userData.length > 0) console.log('no user data recieved from auth0');
            setAuth0User(userData);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        AuthCheck();
    }, [isAuthenticated]);
    useEffect(() => {
        AuthCheck();
        if (sessionStorage.getItem('logout_message') != null) {
            setIdle(true);
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            fetchData();
        }
        PushToRoute();
    }, [activeTeam]);

    useEffect(() => {
        if (!activeTeam.length > 0 && teams.length > 0) {
            InsertActiveTeam({
                id: teams[0].id
            });
        }
    }, [userProfile]);
    const [dataChanged, setDataChanged] = useState();
    const fetchData = async () => {
        const promises = [
            GetTeamUsers(),
            GetLists(),
            GetServices(),
            GetSuppliers(),
            GetReferencedTags(),
            GetProjectTasks(),
            GetAssetMembers(),
            GetCosts(),
            GetReferencedAssetLinks(),
            GetApiRoutes(),
            GetDashboardData(),
            GetContracts({
                contract_terms: null,
                contract_type: null,
                id: null,
                integration: null,
                owners: null,
                project: null,
                software: null
            }),
            GetIntegrations({
                transfer_type: null,
                type: null,
                owners: null,
                supplier: null,
                start_software: null,
                end_software: null
            }),
            GetPractices({
                id: null,
                category: null,
                owners: null
            }),
            GetSoftwares({
                user_data_sensitivity: null,
                supplier: null,
                user: null,
                location: null,
                lifecycle: null,
                effectiveness: null,
                security_criticalness: null,
                practice: null
            }),
            GetProjects({
                practice: null,
                status: null,
                owners: null,
                id: null
            })
        ];
        try {
            const result = await Promise.all(promises);
            setDataChanged(!dataChanged); // This line will execute after all promises resolve
        } catch (error) {
            // Handle errors from any of the promises if needed
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        if (isAuthenticated) GetLists();
    }, [isAuthenticated]);

    useEffect(() => {
        setUserDataSensitivityList(
            lists.user_data_sensitivity && lists.user_data_sensitivity.length > 0
                ? lists.user_data_sensitivity
                : []
        );
        setRoles(lists.roles && lists.roles.length > 0 ? lists.roles : []);
        setLifecycleList(lists.lifecycle && lists.lifecycle.length > 0 ? lists.lifecycle : []);
        setPracticeMaturityList(
            lists.practices_maturity && lists.practices_maturity.length > 0
                ? lists.practices_maturity
                : []
        );
        setCategoriesList(lists.categories && lists.categories.length > 0 ? lists.categories : []);
        setCurrenciesList(lists.currencies && lists.currencies.length > 0 ? lists.currencies : []);
        setVatList(lists.vats && lists.vats.length > 0 ? lists.vats : []);
        setServicesList(
            lists.services_list && lists.services_list.length > 0 ? lists.services_list : []
        );
        setSoftwaresList(
            lists.softwares_list && lists.softwares_list.length > 0 ? lists.softwares_list : []
        );
        setSoftwaresLocations(
            lists.software_locations && lists.software_locations.length > 0
                ? lists.software_locations
                : []
        );
        setSuppliersList(
            lists.suppliers_list && lists.suppliers_list.length > 0 ? lists.suppliers_list : []
        );
        setPracticesList(
            lists.practices_list && lists.practices_list.length > 0 ? lists.practices_list : []
        );
        setPracticeCategories(
            lists.practices_categories && lists.practices_categories.length > 0
                ? lists.practices_categories
                : []
        );
        setContractTerms(
            lists.contract_terms && lists.contract_terms.length > 0 ? lists.contract_terms : []
        );
        setContractTypes(
            lists.contract_types && lists.contract_types.length > 0 ? lists.contract_types : []
        );
        setProjectStatusList(
            lists.project_status && lists.project_status.length > 0 ? lists.project_status : []
        );
        setTagsList(lists.tags && lists.tags.length > 0 ? lists.tags : []);
        setEffectivenessList(
            lists.effectiveness && lists.effectiveness.length > 0 ? lists.effectiveness : []
        );
        setSecurityCriticalnessList(
            lists.security_criticalness && lists.security_criticalness.length > 0
                ? lists.security_criticalness
                : []
        );
        setIntegrationTransferTypes(
            lists.integration_transfer_types && lists.integration_transfer_types.length > 0
                ? lists.integration_transfer_types
                : []
        );
        setIntegrationTypes(
            lists.integration_types && lists.integration_types.length > 0
                ? lists.integration_types
                : []
        );
        setUserFeatureRolesList(
            lists.user_feature_roles && lists.user_feature_roles.length > 0
                ? lists.user_feature_roles
                : []
        );
    }, [lists]);

    useEffect(() => {
        InitialFilterData();
    }, [lists, dataChanged]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setNewElement(false);
        }, 2000);
        return () => clearTimeout(timer);
    }, [newElement]);
    const dropdownRef = useRef();
    const sortRef = useRef();
    const filterRef = useRef();
    const [openDropdown, setOpenDropdown] = useState(false);
    const [openSorting, setOpenSorting] = useState(false);
    const [openFiltering, setOpenFiltering] = useState(false);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (openDropdown && dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setOpenDropdown(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [openDropdown]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (sortRef && sortRef.current && !sortRef.current.contains(e.target)) {
                setOpenSorting(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [openSorting]);
    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (filterRef && filterRef.current && !filterRef.current.contains(e.target)) {
                setOpenFiltering(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [openFiltering]);
    const [idle, setIdle] = useState(false);

    return (
        <AppContext.Provider
            value={{
                setUserProfile,
                idle,
                setIdle,
                setOpenFiltering,
                openFiltering,
                filterRef,
                setOpenSorting,
                openSorting,
                sortRef,
                setOpenDropdown,
                openDropdown,
                dropdownRef,
                insert,
                Logout,
                AuthCheck,
                loadingReferencedAssetLinks,
                loadingIntegrations,
                loadingIntegration,
                setLoadingIntegrations,
                loadingMapElements,
                loadingDashboard,
                loadingSoftware,
                loadingSoftwares,
                loadingUserProfile,
                loadingPractices,
                loadingPractice,
                loadingProjects,
                loadingProject,
                loadingContracts,
                loadingContract,
                loadingReferencedTags,
                loadingFiles,
                loadingUsers,
                loadingAuthentication,
                loadingApiRoutes,
                loadingAssetMembers,
                loadingServices,
                loadingCost,
                loadingCosts,
                services,
                servicesList,
                loadingReference,
                loadingLists,
                loadingNotificationTypes,
                loadingNotifications,
                loadingReferencesArray,
                loadingTeams,
                loadingTeam,
                loadingActiveTeam,
                activeTeam,
                teams,
                noTeam,
                effectivenessList,
                teamMembers,
                InsertAssetMember,
                DeleteAssetMember,
                InsertReferencedTag,
                apiRoutes,
                loadingEmail,
                InsertEmail,
                InsertTag,
                DeleteTag,
                GetReferencedTags,
                sideBarOpen,
                setSideBarOpen,
                InsertSupplier,
                PushToRoute,
                mapElements,
                mapElement,
                GetMapElement,
                InsertMapElements,
                DeleteMapElement,
                GetMapElements,
                loadingTasks,
                setLoadingTasks,
                setLoadingProjects,
                loadingSuppliers,
                setLoadingSuppliers,
                DeleteSupplier,
                FirstLoginCheck,
                // CheckAuth,
                lists,

                // references

                DeleteReference,
                DeleteReferenceRow,
                InsertReference,
                GetReferences,
                GetReference,
                GetReferencesArray,
                referencedSoftwares,
                referencedContracts,
                referencedProjects,
                referencedIntegrations,
                referencedPractices,
                referencedTags,
                referencedCosts,
                referencedSuppliers,
                referencesArray,

                // references

                SetLocationPath,
                logs,
                newLogs,
                GetLogs,
                GetNewLogs,
                newElement,
                setNewElement,
                GetTeams,
                firstLogin,
                setFirstLogin,

                tagsList,
                GetTeamUsers,
                teamUser,
                GetTeamUser,
                DeleteTeamUser,
                userProfile,
                users,
                InsertUser,
                InsertTeamUser,
                InsertTeam,
                InviteUser,
                RevokeInvitation,
                roles,
                DeleteUser,
                userDataSensitivityList,
                billingFrequenciesList,

                costs,
                cost,
                GetCosts,
                GetCost,
                InsertCost,
                DeleteCost,

                appLocation,
                setAppLocation,
                loading,
                setLoading,
                categoriesList,
                GetArticles,
                articles,
                notifyMessage,
                setNotifyMessage,
                toggleComments,
                setToggleComments,
                toggleTasks,
                setToggleTasks,
                lifecycleList,
                practiceMaturityList,
                currenciesList,
                vatList,
                InsertActiveTeam,
                InsertIntegration,
                DeleteIntegration,
                GetIntegrations,
                GetIntegration,
                integration,
                integrations,
                integrationTypes,
                integrationTransferTypes,
                userFeatureRolesList,

                InsertSoftware,
                GetSoftwares,
                DeleteSoftware,
                GetSoftware,
                softwares,
                software,
                softwaresList,
                softwaresLocations,

                // GetPracticesList,
                GetPractices,
                InsertPractice,
                DeletePractice,
                GetPractice,
                practice,
                practicesList,
                practices,
                practiceCategories,
                suppliers,
                suppliersList,
                supplier,

                InsertProject,
                DeleteProject,
                GetProjects,
                GetProject,
                projectStatusList,
                project,
                projects,
                GetFiles,
                DeleteFile,
                files,

                InsertProjectTask,
                DeleteProjectTask,
                projectTasks,
                GetProjectTasks,

                GetSupplier,
                GetSuppliers,
                GetContract,
                GetContracts,
                InsertContract,
                DeleteContract,
                contractTerms,
                contractTypes,
                contracts,
                contract,

                dashboardData,

                GetNotes,
                notes,
                DeleteNote,

                GetDashboardData,
                softwaresCount,
                softwaresTotalCost,
                softwaresByLocation,
                softwaresLifecycle,
                projectsCount,
                projectsCountByStatus,
                projectsTotalCost,
                practicesCount,
                practicesCountByType,
                practiceMaturity,
                integrationsCount,
                integrationsTotalCost,
                integrationsByType,
                contractsTotalCount,
                contractsCountByType,
                contractsCountByExpirationDate,
                securityCriticalnessList,

                DeleteReferencedAssetLink,
                referencedAssetLinks,
                InsertReferencedAssetLink,

                editSoftwaresTable,
                setEditSoftwaresTable,
                viewSoftwaresTable,
                setViewSoftwaresTable,
                viewSoftwaresCards,
                setViewSoftwaresCards,

                filters,
                setFilters,
                resetFilters,
                resetFilter,
                getFilterValue,
                checkFilterValues,
                // notifications
                GetNotifications,
                InsertNotification,
                notifications,
                DeleteNotification,
                notificationTypes,
                GetNotificationTypes,

                selectedTags,
                selectedTagsId,
                setSelectedTags,
                setSelectedTagsId
            }}>
            {children}
        </AppContext.Provider>
    );
};

export default Provider;
