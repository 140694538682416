import React, { useContext } from 'react';
import styled from 'styled-components';
import { AppContext } from '../../context/Context';
import { Button } from '../buttons/Button';
import { useForm } from 'react-hook-form';
import Input from '../inputs/Input';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import FormLayout from './FormLayout';
import BannerInstructions from '../utilities/BannerInstructions';
import Profile from '../../pages/Profile';

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: ${(props) => props.theme.grid.divider_4};
`;

const Wrapper = styled.div`
    z-index: 1000000000;
    position: fixed;
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray_30};
    visibility: hidden;
    top: 0px;
    right: 0px;
    overflow-y: auto;
    min-width: 600px;
    max-width: 600px;
    border-left: 1px solid ${(props) => props.theme.colors.gray_30};
    ${({ panelOpen }) =>
        panelOpen &&
        `
		visibility: visible;
		-webkit-transition: right 0.8s ease-in-out;
		-moz-transition: right 0.8s ease-in-out;
		-o-transition: right 0.8s ease-in-out;
		transition: right 0.8s ease-in-out;
	`}
`;

const Content = styled.div`
    margin: ${(props) => props.theme.grid.divider_8};
`;

const Overlay = styled.div`
    z-index: 1000000;
    position: fixed;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
    visibility: hidden;
    width: 100%;
    ${({ panelOpen }) =>
        panelOpen &&
        `
		visibility: visible;
		-webkit-transition: right 0.8s ease-in-out;
		-moz-transition: right 0.8s ease-in-out;
		-o-transition: right 0.8s ease-in-out;
		transition: right 0.8s ease-in-out;
	`}
`;

const Title = styled.h4`
    margin-bottom: ${(props) => props.theme.grid.divider_4};
`;

const FormAddTeam = ({ title, open, toggleOpen }) => {
    const { InsertTeam, userProfile } = useContext(AppContext);
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();
    const onSubmit = (data) => {
        InsertTeam({
            title: data.title,
            id: null
        });
        toggleOpen(false);
        reset();
    };

    return (
        <FormLayout title={title} open={open} toggleOpen={toggleOpen}>
            {userProfile.length > 0 &&
            userProfile[0].permissions.length > 0 &&
            userProfile[0].permissions[0].permission === 'api_manager' &&
            userProfile[0].permissions[0].access === true ? (
                <div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Input label="Tiimin nimi" name="title" register={register} required />
                        {errors.title && <p>Tiimin nimi on pakollinen tieto.</p>}

                        <ButtonRow>
                            <Button primary dividerRight type="submit">
                                Luo
                            </Button>
                            <Button type="reset" white onClick={() => toggleOpen(!toggleOpen)}>
                                Peruuta
                            </Button>
                        </ButtonRow>
                    </form>
                </div>
            ) : (
                <BannerInstructions
                    title="Käyttöoikeutesi ei riitä suorittamaan täytä toimintoa"
                    onClick={() => toggleOpen(!toggleOpen)}
                    buttonText="Peruuta"
                    category="Ohje"
                    card={true}
                />
            )}
        </FormLayout>
    );
};

export default FormAddTeam;
