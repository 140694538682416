import React from 'react';
import styled from 'styled-components';
import { device } from '../../device';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../modals/Modal';

const Wrapper = styled.div`
    z-index: 1000000000;
    // position: fixed;
    // height: calc(100% + 48px);
    background-color: ${(props) => props.theme.colors.gray_30};
    visibility: hidden;
    top: 80px;
    margin-right: auto;
    margin-left: auto;
    overflow-y: auto;
    min-width: 400px;
    max-width: 400px;
    border-left: 1px solid ${(props) => props.theme.colors.gray_30};
    ${({ panelOpen }) =>
        panelOpen &&
        `
		visibility: visible;
		-webkit-transition: right 0.8s ease-in-out;
		-moz-transition: right 0.8s ease-in-out;
		-o-transition: right 0.8s ease-in-out;
		transition: right 0.8s ease-in-out;
	`}
    @media ${device.laptop} {
        min-width: 100%;
        max-width: 100%;
        margin-top: 60px;
        border-left: none;
    }
`;

const Content = styled.div`
    // margin: ${(props) => props.theme.grid.divider_4};
    @media ${device.laptop} {
        margin: ${(props) => props.theme.grid.divider_2};
        padding-bottom: ${(props) => props.theme.grid.divider_8};
    }
`;

const Overlay = styled.div`
    z-index: 1000000;
    position: fixed;
    min-height: 100%;
    background: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
    visibility: hidden;
    width: 100%;
    ${({ panelOpen }) =>
        panelOpen &&
        `
		visibility: visible;
		-webkit-transition: right 0.8s ease-in-out;
		-moz-transition: right 0.8s ease-in-out;
		-o-transition: right 0.8s ease-in-out;
		transition: right 0.8s ease-in-out;
	`}
`;
const Icon = styled(FontAwesomeIcon)`
    z-index: 1000000000;
    margin-right: auto;
    float: right;
    font-size: 32px;
    color: ${(props) => props.theme.colors.fontDarkLight};
    &:hover {
        cursor: pointer;
    }
    @media ${device.laptop} {
        font-size: 22px;
    }
`;

const FormLayout = ({ children, open, toggleOpen, title }) => {
    return (
        <Modal title={title || ''} open={open} openModal={() => toggleOpen(!open)}>
            {/* <Wrapper panelOpen={open}> */}
            <Content>
                {/* <Icon icon={faTimes} onClick={() => toggleOpen(!open)} /> */}
                {children}
            </Content>
            {/* </Wrapper> */}
            {/* <Overlay panelOpen={open} /> */}
        </Modal>
    );
};

export default FormLayout;
