import React, { useEffect, useContext } from 'react';
import { AppContext } from '../context/Context';
import { useHistory } from 'react-router-dom';

const WhitePage = () => {
    const history = useHistory();
    const { firstLogin, userProfile } = useContext(AppContext);
    useEffect(() => {
        userProfile.length > 0 && firstLogin === false ? history.push('/dashboardpage') : null;
    }, [firstLogin]);
    return <div></div>;
};

export default WhitePage;
