import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../modals/Modal';
import { AppContext } from '../../../context/Context';
import Button from '../../buttons/Button';
import Input from '../../inputs/Input';
import SpinnerSmall from '../../spinners/SpinnerSmall';
import { useForm } from 'react-hook-form';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: ${(props) => props.theme.grid.divider_4};
`;
const TextCentered = styled.div`
    text-align: center;
`;
const FormContent = styled.div`
    padding: 16px;
`;

const FirstLoginModal = () => {
    const history = useHistory();

    const {
        auth0User,
        setFirstLogin,
        firstLogin,
        FirstLoginCheck,
        InsertUser,
        loadingUsers,
        loadingFistLoginCheck,
        userProfile,
        loadingUserProfile,
        Logout,
        AuthCheck
    } = useContext(AppContext);
    const { user } = useAuth0();
    const [localLoading, setLocalLoading] = useState(false);
    const [naming, setNaming] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();
    const onSubmit = async (data) => {
        setLocalLoading(true);
        try {
            const response = await InsertUser({
                first_name: data.first_name || null,
                last_name: data.last_name || null,
                email: user.email || null
                // id: userProfile[0].id || null
            });
            if (response.success === true) {
                setNaming(true);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLocalLoading(false);
        }

        reset();
    };
    useEffect(() => {
        reset({
            first_name: userProfile.length > 0 ? userProfile[0].first_name : '',
            last_name: userProfile.length > 0 ? userProfile[0].last_name : ''
        });
    }, []);
    const CheckName = () => {
        if (firstLogin === true) {
            history.push('/welcome');
            return true;
        }
    };
    return (
        <Modal
            open={CheckName()}
            openModal={() => setModalOpen(!modalOpen)}
            title=""
            closeable={false}>
            {localLoading || loadingUserProfile ? (
                <SpinnerSmall />
            ) : (
                <FormContent>
                    {naming === true ? (
                        <div>
                            <TextCentered>
                                <h1>Hienoa! </h1>
                                <br></br>
                                {/* <h2> Kiitos {userProfile[0].first_name}</h2> */}
                                <br></br>
                                <h3>✅ Tietosi on tallennettu</h3>
                                <br></br>

                                <p>Olet valmis aloittamaan MyDigiMap:in käyttämisen.</p>
                                <ButtonRow>
                                    <Button
                                        primary
                                        dividerRight
                                        type="button"
                                        onClick={() => {
                                            setNaming(false),
                                                setFirstLogin(false),
                                                history.push('/dashboardpage'),
                                                AuthCheck();
                                        }}>
                                        Aloitetaan
                                    </Button>
                                </ButtonRow>
                            </TextCentered>
                        </div>
                    ) : (
                        <div>
                            <TextCentered>
                                {/* <h1>Hei! 👋</h1> */}
                                <br></br>
                                <h3>👋 Tervetuloa MyDigiMapin pariin!</h3>
                                <br></br>
                                <h5>Tarkista, että tietosi ovat oikein.</h5>
                                <br></br>
                            </TextCentered>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Input
                                    label="Etunimi"
                                    name="first_name"
                                    register={register}
                                    disabled={false}
                                    required
                                />
                                {errors.first_name && <p>Etunimi on pakollinen tieto.</p>}
                                <Input
                                    label="Sukunimi"
                                    name="last_name"
                                    disabled={false}
                                    register={register}
                                    required
                                />
                                {errors.last_name && <p>Sukunimi on pakollinen tieto.</p>}

                                <ButtonRow>
                                    <Button success={true} dividerRight type="submit">
                                        Tallenna
                                    </Button>
                                    <Button
                                        white
                                        type="button"
                                        onClick={() => {
                                            reset(), Logout();
                                        }}>
                                        Peruuta ja kirjaudu ulos
                                    </Button>
                                </ButtonRow>
                            </form>
                        </div>
                    )}
                </FormContent>
            )}
        </Modal>
    );
};

export default FirstLoginModal;
