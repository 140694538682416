import React from 'react';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { device } from '../device';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../components/buttons/Button';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    padding: ${(props) => props.theme.grid.divider_4};
    margin-bottom: ${(props) => props.theme.grid.divider_4};
    background-color: ${(props) => props.theme.colors.white};
    border: 1px solid ${(props) => props.theme.colors.gray_40};
    border-radius: 8px;
    @media ${device.laptop} {
        padding: 0px;
        background-color: transparent;
        border: none;
    }
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
`;
const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
`;

const Icon = styled(FontAwesomeIcon)`
    align-self: center;
    font-size: 44px;
    justify-content: center;
    color: ${(props) => props.theme.colors.primary_100};
    margin-right: 24px;
    @media ${device.laptop} {
        font-size: 32px;
        margin-right: 16px;
        margin: 8px;
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const TitleCard = ({ children, title, edit, icon }) => {
    return (
        <Wrapper>
            <Header>
                <Title>
                    <h4 style={{ fontWeight: 'bold' }}>{title || '-'}</h4>
                </Title>
                <ButtonRow>
                    <Button
                        primary
                        small
                        type="button"
                        onClick={() => {
                            edit(!edit);
                        }}>
                        Muokkaa
                    </Button>
                </ButtonRow>
            </Header>

            <Content>{children}</Content>
        </Wrapper>
    );
};

export default TitleCard;
